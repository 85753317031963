import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AROUND } from "../../../constants";
import { CameraSelector } from '../../CameraSelector';
import { MicSelector } from '../../MicSelector';
import { MuteIcon } from '../MuteIcon';
import { MuteVideoIcon } from '../MuteVideoIcon';
import { ShareScreenAround } from './ShareScreenAround';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: 20
  },
}));

export const AroundControls = ({
  toggleScreenshare,
  screenShareOn,
  mics,
  setLocalAudioStreams,
  whiteboard,
  setWhiteboard,
  audioAssignedId,
  videoAssignedId,
  audioState,
  setAudioState,
  cameras,
  setVideoStreams,
  videoMuted,
  localVideoStreams,
  setVideoMuted,
  type,
  sinks }) => {
  const classes = useStyles();
  if (type !== "producer") {
    return <></>
  }
  return (
    <div>
        {/*
        // @ts-ignore */}
      <MuteIcon
        view={AROUND}
        setLocalAudioStreams={setLocalAudioStreams}
        audioState={audioState}
        peerId={"local"}
        setAudioState={setAudioState}
      />

        {/*
        // @ts-ignore */}
      <MicSelector
        assignedId={audioAssignedId}
        view={AROUND}
        mics={mics}
      />
      <span className={classes.divider}>{" "}</span>

        {/*
        // @ts-ignore */}
      <MuteVideoIcon
        view={AROUND}
        setVideoStreams={setVideoStreams}
        videoMuted={videoMuted}
        localVideoStreams={localVideoStreams}
        setVideoMuted={setVideoMuted}
      />

        {/*
        // @ts-ignore */}
      <CameraSelector
        assignedId={videoAssignedId}
        view={AROUND}
        cameras={cameras}
      />
      <span className={classes.divider}>{" "}</span>
      <ShareScreenAround
        screenShareOn={screenShareOn}
        toggleScreenshare={toggleScreenshare}
      />
      <span className={classes.divider}>{" "}</span>
      {/* <WhiteboardControls 
        setWhiteboard={setWhiteboard}
      /> */}
    </div>
  )
}
