
import React from 'react'
import { IndividualVideo } from './layouts/IndividualVideo'
import { Whiteboard } from './Whiteboard';

export const WhiteboardFullscreenLayout = (props) => {
  const nonScreenSharedVideos =  props.videoStreams.filter(x => x.assignedId !== props.someoneIsSharing.assignedId);
  const {isMobile} = props.isMobile;

  const getStyles = () => {
    if(isMobile) {
      return {width: "217px", height: "130.5px"}
    }
    return {width: "290px", height: "174px"}
  }
  return (
    <div style={{padding: 20}}>
      {/* <div style={{display: "flex", justifyContent: "center", justifyItems: "center"}}>
          {nonScreenSharedVideos.map(videoStream => <div style={getStyles()}>
            <IndividualVideo {...props} key={videoStream.assignedId} {...videoStream} />  
          </div>)}
      </div> */}
      <div>
      {/*
      @ts-ignore*/}
      <Whiteboard
        uaLayout={props.uaLayout}
        type={props.type}
        videoStreams={props.videoStreams}
        setVideoStreams={props.setVideoStreams}
        whiteBoardData={props.whiteBoardData}
        setWhiteBoardData={props.setWhiteBoardData}
        currentPage={props.currentPage}
        setCurrentPage={props.setCurrentPage}
      />
      </div>
    </div>
  )
}
