import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, Typography, Slide, IconButton } from '@material-ui/core';
import { Grid } from '@material-ui/core'
import React, { useState, useLayoutEffect } from 'react'
import PermissionModal from '../../PermissionModal';
import Chat from '../../chat/Chat'
import { ControlsNew } from '../../ControlsNew'
import { Videos } from '../../Videos'
import ChatIcon from '@material-ui/icons/Chat';
import {HlsComponent} from "../../HlsComponent";
import Pages from '../../Pages';
import config from '../../../config';


let mouseInterval;

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenLayout = ({
  eventKind,
  destroyEndpoint,
  setLeft,
  setChat,
  whiteboard,
  chat,
  name,
  room,
  someoneIsSharing,
  simulcast,
  setVideoStreams,
  pinned,
  solo,
  pollData,
  setPollData,
  layout,
  videoStreams,
  setWhiteboard,
  localVideoStreams,
  setLocalAudioStreams,
  setVideoAssignedId,
  videoAssignedId,
  audioAssignedId,
  cameras,
  mics,
  hlsUrl,
  sinks,
  cameraReplaced,
  setLocalVideoStreams,
  setPinned,
  setSolo,
  setAudioState,
  setControl,
  audioState,
  videoMuted,
  setVideoMuted,
  setVideoDevices,
  theme,
  type,
  changeLayout,
  setLeftRotationHack,
  setRightRotationHack,
  toggleScreenshare,
  showScreenshareButton,
  screenShareOn,
  peers,
  messages,
  setMessages,
  videoPermissionError, setVideoPermissionError,
  audioPermissionError, setAudioPermissionError,
  pdfData,
  pdfSlide,
  setPdfData,
  setPdfSlide,
  whiteBoardData,
  setWhiteBoardData,
  currentPage,
  setCurrentPage,
  accessKey,
  token,
  quality,
  setQuality,
  uaLayout,
}) => {
  const [mouseNotMoved, setMouseNotMoved] = useState(true);
  const [pagesFetched, setPagesFetched] = useState(0);
  const [activePdf, setActivePdf] = useState();
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();
  const isMobile = width < 960 && height > width;
  const isMid = width < 1300 && height > width;
  const isMobileLandscape = width < 960 && width > height;

  const mouseMoved = () => {
    if (mouseInterval) {
      window.clearInterval(mouseInterval);
    }
    // @ts-ignore
    if (window.adapter && !window.adapter.broadcasting) {
      setMouseNotMoved(true);
    }
    mouseInterval = window.setTimeout(() => {
      setMouseNotMoved(false);
    }, 2500)
  }

  const fetchPdfs = (file :any) => {
    setIsPdfLoading(true)
    var formData = new FormData();
    formData.append("file", file);

    var request = new XMLHttpRequest();
    request.open("POST", `${config.pdfUploadRoute}?currentPage=${pagesFetched}&totalPages=${activePdf?.totalPages}`);
    request.setRequestHeader('Accept', 'application/pdf');
    request.send(formData);
    request.onprogress = function () {
      if (request.readyState === 3) {
        setIsPdfLoading(false)
        try {
          const { path, pages, totalPages } = JSON.parse(request.response);
          pages.forEach((p :any, i :number) => {
            window.adapter.addPage({strokes:[], background: p})
          })
          setActivePdf({file: file, totalPages, hasMore: totalPages > pagesFetched + 3})
          setPagesFetched(pagesFetched + 3);
        } catch(e) {

        }
      }
    }
  };

  const [loaded, setLoaded] = useState(false);

  const _getVideosEl = () => {
    return <div style={{
      "minHeight": "100vh", display: "flex",
      justifyContent: "center",
      justifyItems: "center",
      flexDirection: "column"
    }}>
      {!hlsUrl && <Videos
        screenShareOn={screenShareOn}
        uaLayout={uaLayout}
        isMobile={isMobile}
        isMid={isMid}
        isMobileLandscape={isMobileLandscape}
        pdfSlide={pdfSlide}
        pdfData={pdfData}
        setPdfSlide={setPdfSlide}
        type={type}
        whiteboard={whiteboard}
        setLoaded={setLoaded}
        loaded={loaded}
        myName={name}
        someoneIsSharing={someoneIsSharing}
        simulcast={simulcast}
        setVideoStreams={setVideoStreams}
        mouseNotMoved={mouseNotMoved}
        pinned={pinned}
        solo={solo}
        layout={layout}
        videoStreams={videoStreams}
        localVideoStreams={localVideoStreams}
        setLocalAudioStreams={setLocalAudioStreams}
        setVideoAssignedId={setVideoAssignedId}
        setLocalVideoStreams={setLocalVideoStreams}
        setPinned={setPinned}
        setSolo={setSolo}
        setAudioState={setAudioState}
        setControl
        audioState={audioState}
        whiteBoardData={whiteBoardData}
            setWhiteBoardData={setWhiteBoardData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            activePdf={activePdf}
      />}
        {hlsUrl && <HlsComponent hlsUrl={hlsUrl} />}

      <div style={{ marginTop: uaLayout ? 0 : 10, transition: "visibility 0s, opacity .5s cubic-bezier(0.4,0.0,0.2,1)", opacity: mouseNotMoved ? 1 : 0, visibility: mouseNotMoved ? "visible" : "hidden" }}>

        {/*
    // @ts-ignore */}
        <center>
          {loaded && type == "producer" && <Pages pages={whiteBoardData} currentPage={currentPage} setCurrentPage={setCurrentPage} activePdf={activePdf}
            fetchPdfs={fetchPdfs} />}
          {/*
    // @ts-ignore */}
          {loaded && type && <ControlsNew
            eventKind={eventKind}
            setPollData={setPollData}
            uaLayout={uaLayout}
            pollData={pollData}
            accessKey={accessKey}
            token={token}
            pdfData={pdfData}
            pdfSlide={pdfSlide}
            setPdfData={setPdfData}
            setPdfSlide={setPdfSlide}
            videoAssignedId={videoAssignedId}
            audioAssignedId={audioAssignedId}
            mics={mics}
            cameras={cameras}
            setWhiteboard={setWhiteboard}
            setVideoStreams={setVideoStreams}
            videoMuted={videoMuted}
            setVideoMuted={setVideoMuted}
            audioState={audioState}
            setAudioState={setAudioState}
            theme={theme}
            type={type}
            changeLayout={changeLayout}
            setLeftRotationHack={setLeftRotationHack}
            setRightRotationHack={setRightRotationHack}
            toggleScreenshare={toggleScreenshare}
            showScreenshareButton={!someoneIsSharing.peerId}
            screenShareOn={screenShareOn}
            localVideoStreams={localVideoStreams}
            setLocalAudioStreams={setLocalAudioStreams}
            whiteBoardData={whiteBoardData}
            setWhiteBoardData={setWhiteBoardData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            activePdf={activePdf}
            setActivePdf={setActivePdf}
            fetchPdfs={fetchPdfs}
            isPdfLoading={isPdfLoading}
          />}
          {/*
    // @ts-ignore */}
        </center>
        {/* {loaded && type === "producer" && <DeviceSelector
          cameras={cameras}
          mics={mics}
          sinks={sinks}
          setVideoDevices={setVideoDevices}
          videoAssignedId={videoAssignedId}
          audioAssignedId={audioAssignedId}
          cameraReplaced={(track, stream) => { }}
        />} */}
      </div>
    </div>
  }
  const _getChatEl = () => {
    return <Chat
      room={room}
      destroyEndpoint={destroyEndpoint}
      videoStreams={videoStreams}
      uaLayout={uaLayout}
      isMobileLandscape={isMobileLandscape}
      setChat={setChat}
      type={type}
      pollData={pollData}
      accessKey={accessKey}
      token={token}
      quality={quality}
      setQuality={setQuality}
      setLeft={setLeft}
      peers={peers}
      name={name}
      messages={messages}
      setMessages={setMessages}
    />
  }
  const _getFullLayoutWithChat = () => {
    // added this hack here so that on webview things work as expected on android.
    if (!isMobile) {
      return <>
        <Grid item xs={8} md={9} lg={9} xl={10}>
          {_getVideosEl()}
        </Grid>
        <Grid item xs={4} md={3} lg={3} xl={2}>
          {_getChatEl()}
        </Grid>
      </>
    }
    return <>
      <Grid item xs={12} md={9} lg={9} xl={10}>
        {_getVideosEl()}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={2}>
        {_getChatEl()}
      </Grid>
    </>
  }
  return (// @ts-ignore
    <Dialog fullScreen open={true} onClose={() => { }} TransitionComponent={Transition}>
      <div onMouseMove={mouseMoved}>
        <div style={{

          background: theme.primary,
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          justifyItems: "center",
          flexDirection: "column"
        }}>
          <Grid container>
            {chat && _getFullLayoutWithChat()}
            {/*
    // @ts-ignore */}
            {!chat && <Grid item xs="12" md="12" lg="12" xl="12" >
              {/* <br /> */}
              {_getVideosEl()}
              <div onClick={() => setChat(true)} style={{ width: 50, height: 50, background: "#2c3e50", position: "fixed", right: 20, bottom: 20, borderRadius: 50 }}>
                <IconButton>
                  <ChatIcon style={{ color: "white" }} />
                </IconButton>
              </div>
            </Grid>}
          </Grid>
          <PermissionModal
            videoPermissionError={videoPermissionError}
            setVideoPermissionError={setVideoPermissionError}
            audioPermissionError={audioPermissionError}
            setAudioPermissionError={setAudioPermissionError}
          />
        </div>
      </div>
    </Dialog>
  )
}
