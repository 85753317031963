import { IconButton, makeStyles, CircularProgress } from '@material-ui/core';
import FindReplaceIcon from '@material-ui/icons/FindReplace';import { AROUND } from "../constants";
import React, { useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useRef } from 'react';
import config from "../config";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: "relative",
    display: "inline-block",
    '&:hover': {
      background: "#ced6e0"
    }
  },
  icon: {
    //@ts-ignore
    color: ({theme}) => theme === "dark" ? "white" : "#192a56",
    fontSize: 25, 
    marginRight: 5
  },
  btnSmall: {
    background: "black",
    width: 14,
    height: 14,
    //@ts-ignore
    padding: 10,
    "border-top-left-radius": 5,
    "border-bottom-left-radius": 5,
    "border-top-right-radius": 5,
    "border-bottom-right-radius": 5,
    //@ts-ignore
    padding: 14
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
  btn: {
    background: "#ecf0f1",
    width: 65,
    height: 50,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#dfe4ea"
    },
    fontSize: 20
  }
}));

export const PdfControls = ({ theme,
  view,
  pdfData,
  pdfSlide,
  setPdfData,
  setPdfSlide,
  accessKey,
  token,
  fetchPdfs,
  isPdfLoading
}) => {
  const classes = useStyles({theme});
  const inputRef = useRef(null);

  const toggle = (force) => {
    if (pdfData.route && !force) {
      if (pdfSlide) {
        //@ts-ignore
        window.adapter.setPdfState(null);
        return setPdfSlide(null);
      } else {
        //@ts-ignore
        window.adapter.setPdfState(1);
        return setPdfSlide(1);
      }
    } else {
      inputRef.current.click();  
    }
  }

  // if (view === AROUND) {
  //   return (
  //     <>
  //       <IconButton onClick={toggle} className={classes.btnSmall} size="small">
  //         <CreateIcon  className={classes.btnAroundInner}/>
  //       </IconButton>
  //     </>
  //   )
  // }

  // const sendRequest = (file) => {
  //   setLoading(true)
  //   var formData = new FormData();
  //   formData.append("file", file);

  //   var request = new XMLHttpRequest();
  //   request.open("POST", `${config.pdfUploadRoute}?currentPage=${pagesFetched}`);
  //   request.setRequestHeader('Accept', 'application/pdf');
  //   request.send(formData);
  //   request.onprogress = function () {
  //     if (request.readyState === 3) {
  //       setLoading(false)
  //       try {
  //         const { path, pages } = JSON.parse(request.response);
  //         //debugger;
  //         pages.forEach((p, i) => {
  //           window.adapter.addPage({strokes:[], background: p})
  //         })
  //         setPagesFetched(pagesFetched + 3);
  //         // setPdfData({route: path, pages});
  //         // setPdfSlide(1);
  //         // window.adapter.setPdfState(1, {
  //         //   route: path, pages
  //         // });
  //       } catch(e) {

  //       }
  //     }
  //   }
  // };


  return (
    <>
      {/*
       //@ts-ignore */}
      <IconButton className={classes.btn} style={{border: "1px solid #ced6e0", background: "#f1f2f6", borderRadius: 7}} onClick={() => toggle()} size="small">
        <div>
        <div style={{marginBottom: 0}}>
          {!isPdfLoading && <PictureAsPdfIcon style={{fontSize: 20, color: pdfSlide ? "#27ae60" : ""}} />}
          {isPdfLoading && <CircularProgress size={15} style={{fontSize: 12, color: pdfSlide ? "#27ae60" : ""}} />}

          </div>
          <div style={{fontSize: 11, marginBottom: -5,  color: "black" }}>
            Slides
          </div>
        </div>
        <FindReplaceIcon onClick={(e) => {
            toggle(true)
            e.stopPropagation();
          }} className={classes.dropdown} style={{ position: "absolute", "top": "0", "right": "0", borderRadius: 3, width: 20, height: 20 }} >
          </FindReplaceIcon>
      </IconButton>
      <input onChange={(e) => {
        if (!inputRef.current.files[0]) {
          return;
        }
        fetchPdfs(inputRef.current.files[0])
      }} ref={inputRef} style={{visibility: "hidden", display: "none"}} type="file" name="my_file" id="my-file"></input>
    </>
  )
}
