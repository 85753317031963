
import React, { useEffect } from 'react'
import { IndividualVideo } from './layouts/IndividualVideo'
import { Whiteboard } from './Whiteboard';
import config from '../config';
import { Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {WhiteboardFabric} from "./WhiteboardFabric";
export const PdfLayout = (props) => {

  // const keyDownHandler = (event) => {
  //   if (event.keyCode === 39) {
  //     nextSlide();
  //   }
  //   if (event.keyCode === 37) {
  //     prevSlide();
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("keydown", keyDownHandler);
  //   return () => {
  //     window.removeEventListener("keydown", keyDownHandler);
  //   }
  // }, [])

  const nextSlide = () => {
    if (props.pdfSlide !== props.pdfData.pages) {
      // @ts-ignore
      window.adapter.setPdfState(props.pdfSlide + 1)
      props.setPdfSlide(x => x + 1);
      window.adapter.clearWhiteboard();
    }
  }

  const prevSlide = () => {
    if (props.pdfSlide !== 1) {
      // @ts-ignore
      window.adapter.setPdfState(props.pdfSlide - 1)
      props.setPdfSlide(x => x - 1);
      window.adapter.clearWhiteboard();
    }
  }

  return (
    <div style={{padding: 20}}>
      {/* <div style={{display: "flex", justifyContent: "center", justifyItems: "center"}}>
          {nonScreenSharedVideos.map(videoStream => <div style={getStyles()}>
            <IndividualVideo {...props} key={videoStream.assignedId} {...videoStream} />  
          </div>)}
      </div> */}
      <div>
      <Whiteboard
        pdf={true}
        image={`${config.pdfImageRoute}${props.pdfData.route}/.${props.pdfSlide - 1}${props.isMobile ? "" : ""}.png`}
        type={props.type}
        videoStreams={props.videoStreams}
        setVideoStreams={props.setVideoStreams}
        uaLayout={props.uaLayout}
      />
        {/*{props.uaLayout && props.type !== "producer" && <WhiteboardFabric*/}
        {/*    isMobile={props.isMobile}*/}
        {/*    image={`${config.pdfImageRoute}${props.pdfData.route}/${props.pdfSlide - 1}${props.isMobile ? "" : ""}.png`}*/}
        {/*    // @ts-ignore*/}
        {/*    type={props.type}*/}
        {/*    videoStreams={props.videoStreams}*/}
        {/*    setVideoStreams={props.setVideoStreams}*/}
        {/*    uaLayout={props.uaLayout}*/}
        {/*/>}*/}
      {props.type === "producer" && <div style={{marginTop: -30}}>
        {/*
        @ts-ignore*/}
        <center>
        <Button startIcon={<NavigateBeforeIcon />} variant="contained" style={{marginRight: 10}} color="primary" size="small" onClick={prevSlide}></Button>

        <Button endIcon={<NavigateNextIcon />} variant="contained" color="primary" size="small" onClick={nextSlide}></Button>
          {/*
        @ts-ignore*/}
        </center>
      </div>}
      </div>
    </div>
  )
}
