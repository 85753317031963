import { Grid } from '@material-ui/core'
import React from 'react'
import { IndividualVideo } from './IndividualVideo'

export const Layout5 = (props) => {
    const isMobile = props.isMobile;
    const isMid = props.isMidl

    if (isMobile || isMid) {
      return <div style={{ paddingRight: "2vh", paddingLeft: "2vh" }}>
      <Grid container spacing={2}>
        {props.videoStreams.map((vs, index) => {
          if (index !== 4) {
            return <Grid key={vs.assignedId} item sm={6} md={6} lg={6}>
              <IndividualVideo {...vs} {...props} />
            </Grid>
          }
          return <>
            <Grid key={vs.assignedId} item sm={3} md={3} lg={3}>
            </Grid>
            <Grid key={vs.assignedId} item sm={6} md={6} lg={6}>
              <IndividualVideo {...vs} {...props} />
            </Grid>
          </>
        }
        )}
      </Grid>
    </div>
    }

    if(props.layout === 1) {
      return (
        <div style={{"paddingLeft": "3vw", "paddingRight": "3vw"}}>
          <Grid container  spacing={2} justifyContent={"center"} alignItems={"center"} justify="center">
            {props.videoStreams.map(x => <Grid key={x.assignedId} item sm={12} md={4} lg={4}>
                <IndividualVideo {...props} {...x} />
              </Grid>)}
          </Grid>
        </div>
      )  
    }
  
  return (
    <div style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
      <Grid container spacing={2}>
        {props.videoStreams.map((vs, index) => {
          if (index !== 4) {
            return <Grid key={vs.assignedId} item sm={12} md={6} lg={6}>
              <IndividualVideo hideName={index === 3 ? true : false} {...vs} {...props} />
            </Grid>
          }
          return <div>
              {/*
      @ts-ignore*/}
            <center>
              <div style={{ width: "25vw", marginTop: "-28vw", marginLeft: `27vw` }}>
                <IndividualVideo {...vs} {...props} />
              </div>
                {/*
      @ts-ignore*/}
            </center>
          </div>
        }
        )}
      </Grid>
    </div>
  )
}
