import { Grid, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import config from "../config";
import { BROADCASTER_MODE, FULLSCREEN, JAMMER_MODE, PERFORMER_AND_BROADCASTER, PERFORMER_MODE, PERFORMER_WITH_BROADCASTER, UNACADEMY } from '../constants';
import communicationAdapter from "./communicationAdapter";
import { AroundLayout } from './layouts/parents/AroundLayout';
import { FullScreenLayout } from './layouts/parents/FullScreenLayout';
import { UnacademyLayout } from './layouts/unacademy/UnacademyLayout';

declare global {
  interface Window { adapter: communicationAdapter; }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Stream({ eventKind, destroyEndpoint, uaLayout, setChat, room: roomOuter, theme, admin, audiooff, token, canvas, setRestart, debug, producerState, setForcedSfu, setProducerState, setInitialVideoProps, initialVideoProps, isP2p = true, simulcast = true, accessKey, backendUrl, chat, isFullScreen, view, setLeft, testMode, testModeData, hlsUrl }) {
  const [layout, setLayout] = useState(1);
  const [room, setRoom] = useState(roomOuter || "");
  const [videoDevices, setVideoDevices] = useState([]);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [localVideoStreams, setLocalVideoStreams] = useState([]);
  const [peers, setPeers] = useState([]);
  const [localAudioStreams, setLocalAudioStreams] = useState([]);
  const [audioState, setAudioState] = useState({ local: { audioMuted: audiooff } });
  const [videoMuted, setVideoMuted] = useState(false);
  const [pinned, setPinned] = useState(null);
  const [solo, setSolo] = useState(null);
  const [videoAssignedId, setVideoAssignedId] = useState(null);
  const [audioAssignedId, setAudioAssignedId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [quality, setQuality] = useState(0);
  const [someoneIsSharing, setSomeoneIsSharing] = useState<any>({
    peerId: null,
    assignedId: null
  });
  const [name, setName] = useState("");
  const [videoEls, setVideoEls] = useState([]);
  const [videoStreams, setVideoStreams] = useState([]);
  const [audioStreams, setAudioStreams] = useState([]);
  const [mode, setMode] = useState(JAMMER_MODE);
  const [firstMode, setFirstMode] = useState(false);
  const [unAuthorized, setUnauthorized] = useState(false);
  const [broadcaster, setBroadcaster] = useState(null);
  const [rightRotationHack, setRightRotationHack] = useState(false);
  const [leftRotationHack, setLeftRotationHack] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [type, setType] = useState<"producer" | "consumer" | boolean>(false);
  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);
  const [sinks, setSinks] = useState([]);
  const [audioPermissionError, setAudioPermissionError] = useState(false);
  const [videoPermissionError, setVideoPermissionError] = useState(false);
  const [whiteboard, setWhiteboard] = useState(uaLayout || false);
  const [pdfData, setPdfData] = useState({});
  const [pdfSlide, setPdfSlide] = useState(0);
  const [emojiCount, setEmojiCount] = useState([0, 0, 0]);
  const [pollData, setPollData] = useState({});
  const [whiteBoardData, setWhiteBoardData] = useState([{background: "white", strokes: []}]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    refreshDevices()
    navigator.mediaDevices.ondevicechange = function (event) {
      refreshDevices();
    };
  }, [])

  const refreshDevices = () => {
    let audioDevices = [];
    let videoDevices = [];
    let speakerDevices = [];
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.map(device => {
          // @ts-ignore
          if (device.kind.includes("video")) {
            videoDevices.push(device);
            // @ts-ignore
          } else if (device.kind.includes("audioinput")) {
            audioDevices.push(device);
            // @ts-ignore
          } else if (device.kind.includes("audiooutput")) {
            speakerDevices.push(device);
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
    setCameras(videoDevices);
    setVideoDevices(videoDevices)
    setMics(audioDevices);
    setSinks(speakerDevices);
  }

  useEffect(() => {
    if (videoStreams.length >= config.options.p2pThreshold) {
      if (window.adapter.webrtcType === "p2p") {
        window.adapter.p2pAdapter.sendSwitchToSfu();
        window.adapter.p2pAdapter.switchToSfu();
      }
    }
    consolidateIncomingVideos()
  }, [videoStreams]);

  const consolidateIncomingVideos = () => {
    if (someoneIsSharing.peerId) {
      screenshareSimulcast(someoneIsSharing.peerId);
      return;
    }
    let mapping = {};
    videoStreams.map(({ assignedId }) => {
      mapping[assignedId] = videoStreams.length >= 3 ? 0 : 1;
    });
    if (layout === 2) {
      mapping[videoStreams[0]?.assignedId] = 1;
    }
    if (layout === 1 && Object.keys(videoStreams).length >= 4) {
      videoStreams.map(({ assignedId }) => {
        mapping[assignedId] = 0;
      });
    }
    if (quality == 2) { // 360p
      videoStreams.map(({ assignedId }) => {
        mapping[assignedId] = 0;
      });
    }
    if (window.adapter && window.adapter.consolidateIncomingVideos) {
      window.adapter.consolidateIncomingVideos(mapping)
    }
  }

  useEffect(() => {
    consolidateIncomingVideos();
    // localStorage.setItem("layout-jk", layout)
  }, [layout])

  useEffect(() => {
    consolidateIncomingVideos();
    // localStorage.setItem("layout-jk", layout)
  }, [layout, quality])

  useEffect(() => {
    if (someoneIsSharing.peerId)
      screenshareSimulcast(someoneIsSharing.peerId)
    else
      consolidateIncomingVideos();
  }, [someoneIsSharing.peerId])

  const screenshareSimulcast = (peerId) => {
    if (peerId) {
      let mapping = {};
      videoStreams.map(({ assignedId }) => {
        mapping[assignedId] = 0
      });
      window.adapter.consolidateIncomingVideos(mapping)
    } else {
      consolidateIncomingVideos();
    }
  }

  useEffect(() => {
    rotateLeft();
  }, [leftRotationHack])

  useEffect(() => {
    rotateRight();
  }, [rightRotationHack])

  useEffect(() => {
    // @ts-ignore
    window.videoStreams = videoStreams
  }, [videoStreams])

  useEffect(() => {
    let localVideos = [];
    videoStreams.map(vs => {
      if (vs.peerId === "local") {
        localVideos.push(vs)
      }
    })
    setProducerState(p => ({
      ...p,
      localVideos
    }))
  }, [videoStreams])

  useEffect(() => {
    setProducerState(p => ({
      ...p,
      localAudios: localAudioStreams
    }))
  }, [localAudioStreams])

  const stopAllOutgoingVideos = () => {
    localVideoStreams.map(vs => {
      window.adapter.destroyMediaProducer({
        assignedId: vs.assignedId,
      })
      vs.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    })
    setLocalVideoStreams([]);
    setVideoStreams(streams => streams.filter(vs => vs.peerId !== "local"));
  }

  const receiveAllIncomingVideos = () => {
    window.adapter.resumeAllConsumers();
  }

  const pauseAllIncomingVideos = () => {
    window.adapter.pauseAllConsumers();
  }

  const startAtleastOneVideoIfEmpty = () => {
    let camStarted = false;
    setLocalVideoStreams((localVideoStreams) => {
      if (localVideoStreams.length === 0 && !camStarted) {
        camStarted = true;
        // @ts-ignore
        window.adapter.createVideoProducer({ video: { deviceId: { exact: videoDevices[0].deviceId } } })
      }
      return localVideoStreams;
    })
  }

  useEffect(() => {
    if (!firstMode) {
      // dont do anything if the first time the mode is being set
      // because we already set based on the PERFORMER_AND_BROADCASTER mode
      setFirstMode(true);
      return;
    }
    if (mode === JAMMER_MODE) {
      receiveAllIncomingVideos();
      startAtleastOneVideoIfEmpty();
    }
    if (mode === PERFORMER_MODE) {
      pauseAllIncomingVideos();
      startAtleastOneVideoIfEmpty();
    }
    if (mode === BROADCASTER_MODE) {
      stopAllOutgoingVideos();
      receiveAllIncomingVideos();
    }
    if (mode === PERFORMER_AND_BROADCASTER) {
      receiveAllIncomingVideos();
      startAtleastOneVideoIfEmpty();
    }
    if (mode === PERFORMER_WITH_BROADCASTER) {
      startAtleastOneVideoIfEmpty();
      receiveAllIncomingVideos();
    }
  }, [mode])

  const toggleScreenshare = () => {
    setScreenShareOn(screenShareOn => {
      if (!screenShareOn) {
        // @ts-ignore
        window.adapter.createDesktopProducer({
          video: {
            width: 1920,
            height: 1080
          }
        });
        return screenShareOn;
      } else {
        localVideoStreams.map(vs => {
          if (vs.type === "desktop") {
            window.adapter.destroyMediaProducer({
              assignedId: vs.assignedId,
            })
            vs.stream.getTracks().forEach(function (track) {
              track.stop();
            });
          }
          return "";
        })
        setLocalVideoStreams(vs => vs.filter(s => s.type !== "desktop"));
        sendControlUpdateToNativeApp("screenshare", false);
        return false;
      }
    })
  }
  const startCam = () => {
    let cameraSet = false;
    videoDevices.map(device => {
      if (cameraSet) {
        return;
      }
      Object.keys(window.adapter.videoElProps).map(x => {
        const oldVideoConstraints = window.adapter.videoElProps[x]?.constraints?.video;
        if (!oldVideoConstraints) {
          return;
        }
        let oldVideoDeviceId = window.adapter.videoElProps[x]?.constraints?.video?.deviceId?.exact || window.adapter.videoElProps[x]?.constraints?.video?.deviceId
        if (device.deviceId !== oldVideoDeviceId) {
          // @ts-ignore
          window.adapter.createVideoProducer({ video: { deviceId: device.deviceId } })
          cameraSet = true;
        }
      })
    })
    if (!cameraSet) {
      // @ts-ignore
      window.adapter.createVideoProducer({ video: { deviceId: videoDevices[0].deviceId } })
    }
  }

  const toggleMute = (peerId, newAudioMuted) => {
    setLocalAudioStreams(localAudioStreams => {
      if (peerId === "local") {
        if (!localAudioStreams[0]) {
          if (window.adapter.audioTransmitting) {
            console.warn("Creating audio element!")
            return localAudioStreams;
          }
          if (!newAudioMuted) {
            window.adapter.createAudioProducer({ audio: true });
            window.adapter.muteToggled(false);
            toggleMuteIcon(peerId, newAudioMuted);
          } else {
            console.warn("local audio stream not found, have you provided access?");
          }
          return localAudioStreams;
        }
        if (newAudioMuted) {
          window.adapter.pauseMediaProducer({
            assignedId: localAudioStreams[0].assignedId,
          })
          window.adapter.muteToggled(newAudioMuted);
        } else {
          window.adapter.resumeMediaProducer({ assignedId: localAudioStreams[0].assignedId });
          window.adapter.muteToggled(newAudioMuted);
        }
        toggleMuteIcon(peerId, newAudioMuted);
      } else {
        // user is a remote peer
        window.adapter.muteUser(peerId);
      }
      return localAudioStreams;
    })
  }

  const toggleMuteIcon = (peerId, newAudioMuted) => {
    setAudioState(audioState => ({
      ...audioState,
      [peerId]: {
        ...audioState[peerId],
        audioMuted: newAudioMuted
      }
    }))
  }

  useEffect(() => {
    const obj = { peers: [] };
    videoStreams.map(vs => {
      obj.peers.push({
        name: vs.name,
        assignedId: vs.assignedId,
      })
    })
    sendControlUpdateToNativeApp("streams", obj);
  }, [videoStreams])
  const sendState = () => {
  }

  const muteMe = () => {
    toggleMute("local", true);
  }

  const sendControlUpdateToNativeApp = (controlType, controlState) => {

  }

  const changeLayout = async (val) => {
    await setLayout(layout => (val || val === 0) ? val : (layout + 1) % 3)
    await setPinned(false);
    await setSolo(null);
  }
  const rotateRight = () => {
    let itsDone = false;
    setPinned(pinned => {
      if (itsDone) {
        return pinned;
      }
      itsDone = true;
      setVideoStreams(videoStreams => {
        if (videoStreams.length === 0 || videoStreams.length === 1) {
          return videoStreams
        }
        let videoStreamsTemp = [...videoStreams];
        let pinnedVideoIndex = videoStreamsTemp.findIndex(x => x.assignedId === pinned);
        if (pinnedVideoIndex === -1) {
          let lastElement = videoStreamsTemp[videoStreamsTemp.length - 1];
          videoStreamsTemp.splice(videoStreamsTemp.length - 1, 1);
          videoStreamsTemp.splice(0, 0, lastElement);
          return videoStreamsTemp
        } else {
          let pinnedVideo = videoStreamsTemp[pinnedVideoIndex];
          videoStreamsTemp.splice(pinnedVideoIndex, 1);
          let lastElement = videoStreamsTemp[videoStreamsTemp.length - 1];
          videoStreamsTemp.splice(videoStreamsTemp.length - 1, 1);
          videoStreamsTemp.splice(0, 0, lastElement);
          videoStreamsTemp.splice(pinnedVideoIndex, 0, pinnedVideo);
          return videoStreamsTemp
        }
      })
      return pinned;
    })
  }

  const rotateLeft = () => {
    let itsDone = false;
    setPinned(pinned => {
      if (itsDone) {
        return pinned;
      }
      itsDone = true
      setVideoStreams(videoStreams => {
        if (videoStreams.length === 0 || videoStreams.length === 1) {
          return videoStreams
        }
        let videoStreamsTemp = [...videoStreams];
        let pinnedVideoIndex = videoStreamsTemp.findIndex(x => x.assignedId === pinned);
        if (pinnedVideoIndex === -1) {
          let firstElement = videoStreamsTemp[0];
          videoStreamsTemp.splice(0, 1);
          videoStreamsTemp.push(firstElement);
          return videoStreamsTemp
        } else {
          let pinnedVideo = videoStreamsTemp[pinnedVideoIndex];
          videoStreamsTemp.splice(pinnedVideoIndex, 1);
          let firstElement = videoStreamsTemp[0];
          videoStreamsTemp.splice(0, 1);
          videoStreamsTemp.push(firstElement);
          videoStreamsTemp.splice(pinnedVideoIndex, 0, pinnedVideo);
          return videoStreamsTemp
        }
      })
      return pinned;
    })
  }
  const dataChannelHandler = ({ type, data }) => {
    if (type === "activeUser") {
      setVideoStreams(streams => streams.map(vs => {
        // @ts-ignore
        if ((vs.peerId === "local" ? window.adapter.id : vs.peerId) !== data.peerId) {
          return {
            ...vs,
            isActive: false
          };
        }
        if (vs.isDesktop) {
          return vs;
        }

        return {
          ...vs,
          isActive: true
        }
      }))
    }
  }

  const adapterCallback = ({ type, data }) => {
    if (type === "unauthorized") {
      setUnauthorized(true);
    }
    if (type === "handRaised") {
      setPeers(p => p.map(x => {
        if (x.peerId === data.peerId) {
          return {
            ...x,
            handRaised: data.value
          }
        } else {
          return x;
        }
      }));
    }
    if (type === "makeProducer") {
      window.adapter.makeProducer()
      setType("producer");
    }
    if (type === "makeConsumer") {
      window.adapter.unMakeProducer()
      setType("consumer");
    }
    if (type === "audioDevicePermissionIssue") {
      setAudioPermissionError(true);
    }
    if (type === "videoDevicePermissionIssue") {
      setVideoPermissionError(true);
    }
    if (type === "updateWhiteboard") {
      if (!eventKind) {
        setWhiteboard(data.value);
      }
    }
    if (type === "updatePollData") {
      setPollData(data.pollData);
    }
    if (type === "updatePdfSlide") {
      setPdfSlide(data.pdfSlide);
      if (data.pdfData) {
        setPdfData(data.pdfData);
      }
    }
    if (type === "botmessage") {
      try {
        dataChannelHandler(JSON.parse(data));
      } catch (e) {

      }
    }
    if (type === "changeRecordingLayout") {
      setVideoStreams(vs => [...vs.sort((a, b) => data.
        props.
        videoStreamIds.
        findIndex(x => x === a.assignedId) < data.
          props.
          videoStreamIds
          .findIndex(x => x === b.assignedId) ? -1 : 1)])
    }
    if (type === "isRecording") {
      setIsRecording(data.value);
    }
    if (type === "forceSfu") {
      setForcedSfu(true);
      setRestart(true);
    }

    if (type === "producerScore") {
      setVideoStreams(vs => vs.map(s => {
        if (s.assignedId === data.assignedId) {
          return {
            ...s,
            score: data.score
          }
        }
        return s
      }))
    }

    if (type === "addedDesktopProducer") {
      if (window.adapter.webrtcType === "p2p") {
        window.adapter.p2pAdapter.desktopMediaIds.push(data.mediaId);
      }
    }
    if (type === "transportsCreated" || type === "p2pInitialized") {
      // @ts-ignore
      if (window.adapter.type === "producer") {
        initialVideoProps.forEach(({ constraints, stream, type }) => {
          if (type !== "desktop")
            window.adapter.createVideoProducer(constraints, stream);
          else
            window.adapter.createDesktopProducer(constraints, stream);
        })
      }
    }
    if (type === "signalDisconnected") {
      if (!window.adapter) {
        return;
      }
      setInitialVideoProps(data.videos)
      setRestart(true)
    }
    if (type === "peerAdded") {
      setPeers(p => [...p, {
        name: data.name,
        peerId: data.peerId,
        type: data.kind
      }]);

      setMessages(x => [...x, {
        type: "peerAdded",
        name: data.name
      }]);
    }

    if (type === "peerUpdated") {
      setPeers(p => p.map(x => {
        if (x.peerId === data.peerId) {
          return {
            ...x,
            type: data.kind
          }
        } else {
          return x;
        }
      }));
    }

    if (type === "identity") {
      setBroadcaster(data.broadcaster);
      setType(data.type);
      setRoom(data.room);
      setPeers(data.peers || []);
    } else if (type === "consumemedia") {
      const stream = new MediaStream();
      stream.addTrack(data.track);
      if (data.track.kind === "audio") {
        let audioelement = document.createElement("audio");
        audioelement.setAttribute("autoplay", "true");
        audioelement.setAttribute("playsinline", "true");
        audioelement.setAttribute("id", "audio" + data.assignedId);
        audioelement.setAttribute("peerid", data.peerId);
        audioelement.srcObject = stream;
        setAudioState(astate => {
          window.setTimeout(() => {
            toggleMuteIcon(data.peerId, data.audioMuted);
          }, 50)
          return ({
            ...astate,
            [data.peerId]: {
              audioMuted: data.audioMuted,
              assignedId: data.assignedId
            }
          })
        })
//         document.body.appendChild(audioelement);
// no need to ?
        toggleMuteIcon(data.peerId, data.audioMuted);
        setAudioStreams(vs => [...vs, {
          assignedId: data.assignedId,
          stream: stream,
          peerId: data.peerId
        }])
      } else {
        if (!data.isDesktop) {
          data.track.onmute = () => {
            setVideoStreams(streams => streams.map(vs => {
              if (vs.assignedId !== data.assignedId) {
                return vs;
              }
              return {
                ...vs,
                isMuted: true
              }
            }))
          };
          data.track.onunmute = () => {
            setVideoStreams(streams => streams.map(vs => {
              if (vs.assignedId !== data.assignedId) {
                return vs;
              }
              return {
                ...vs,
                isMuted: false
              }
            }))
          };
        }

        setVideoStreams(vs => [...vs, {
          assignedId: data.assignedId,
          stream: stream,
          peerId: data.peerId,
          filter: "",
          horizontalFlip: false,
          verticalFlip: false,
          name: data.name,
          user_id: data.user_id,
          isMuted: data.isMuted,
          isDesktop: data.isDesktop
        }])
        if (data.isDesktop) {
          setSomeoneIsSharing({
            assignedId: data.assignedId,
            peerId: data.peerId
          });
        }
      }
    }
    if (type === "disconnect") {
      setAudioStreams(vs => vs.filter(s => s.peerId !== data.peerId))
      setPeers(x => x.filter(y => y.peerId !== data.peerId));
      setVideoStreams(vs => {
        setPinned((pinned) => {
          if (vs.filter(s => s.peerId !== data.peerId).findIndex(x => x.assignedId === pinned) === -1) {
            return false;
          }
          return pinned;
        })
        setSolo((solo) => {
          if (vs.filter(s => s.peerId !== data.peerId).findIndex(x => x.assignedId === solo) === -1) {
            return false;
          }
          return solo;
        })

        return vs.filter(s => s.peerId !== data.peerId)
      });
      setSomeoneIsSharing(s => {
        if (s.peerId === data.peerId) {
          return {};
        }
        return s;
      })
    }
    if (type === "destroyMedia") {
      const el = document.querySelector(`.outerDiv-${data.assignedId}`);
      if (el && el.parentNode) {
        setVideoEls(vc => vc.filter((assignedId) => assignedId !== data.assignedId))
        el.parentNode.removeChild(el);
      }
      setVideoStreams(vs => vs.filter(el => el.assignedId !== data.assignedId))
      setPinned((pinned) => {
        if (pinned === data.assignedId) {
          return false;
        }
        return pinned;
      })
      setSolo((solo) => {
        if (solo === data.assignedId) {
          return false
        }
        return solo;
      })
      setSomeoneIsSharing(s => {
        if (s.assignedId === data.assignedId) {
          return {};
        }
        return s;
      })
      sendState()
    }
    if (type === "videocreated") {
      setVideoStreams(vs => [...vs, {
        assignedId: data.assignedId,
        stream: data.stream,
        peerId: "local",
        filter: "",
        horizontalFlip: false,
        verticalFlip: false,
        isLocal: true,
        name: "You",
        user_id: "local"
      }]);
      setVideoAssignedId(data.assignedId);
      setMode((mode) => {
        // This might get tricky with performer with broadcaster mode
        // if (mode === PERFORMER_MODE) {
        //   window.adapter.pauseMediaProducer({ assignedId: data.assignedId });
        // }
        return mode;
      })
      setLocalVideoStreams(vs => [...vs, {
        stream: data.stream,
        assignedId: data.assignedId,
        isLocal: true,
        type: "camera"
      }])
      sendControlUpdateToNativeApp("camera", true);
    }
    if (type === "audiocreated") {
      setAudioAssignedId(data.assignedId);
      setAudioState(astate => ({
        ...astate,
        ["local"]: {
          audioMuted: false
        }
      }));
      setLocalAudioStreams(vs => [...vs, {
        stream: data.stream,
        assignedId: data.assignedId,
        isLocal: true
      }])
    }
    if (type === "desktopvideocreated") {
      // sendControlUpdateToNativeApp("screenshare", true);
      setScreenShareOn(true)
      setLocalVideoStreams(vs => [...vs, {
        stream: data.stream,
        assignedId: data.assignedId,
        isLocal: true,
        type: "desktop"
      }])
    }
    if (type === "muteUser") {
      muteMe();
    };
    if (type === "toggleVideoMute") {
      setVideoStreams(streams => streams.map(vs => {
        if (vs.peerId !== data.peerId) {
          return vs;
        }
        return {
          ...vs,
          isMuted: data.videoMuted
        }
      }));
    };
    if (type === "toggleMute") {
      toggleMuteIcon(data.peerId, data.audioMuted)
    }
    if (type === "desktopVideoDestroyed") {
      setLocalVideoStreams(vs => vs.filter(s => s.type !== "desktop"));
      setScreenShareOn(false)
      sendState();
    }
    if (type === "sendMessage") {
      setMessages(m => [...m, data])
    }
    if (type === "setBroadcaster") {
      setBroadcaster(data.peerId)
      if (data.moveToJammer) {
        setMode(JAMMER_MODE)
      }
    }
    if (type === "replacedLocalTrack") {
      setVideoStreams(vs => vs.map(s => {
        if (s.assignedId === data.assignedId) {
          return {
            ...s,
            stream: data.stream
          }
        }
        return s
      }))
    }
    if (type === "flip") {
      if (data.direction === "horizontal") {
        setVideoStreams(streams => streams.map(vs => {
          if (vs.assignedId !== data.assignedId) {
            return vs;
          }
          return {
            ...vs,
            horizontalFlip: !vs.horizontalFlip
          }
        }))
      } else {
        setVideoStreams(streams => streams.map(vs => {
          if (vs.assignedId !== data.assignedId) {
            return vs;
          }
          return {
            ...vs,
            verticalFlip: !vs.verticalFlip
          }
        }))
      }
    }
    if (type === "setname") {
      setName(data.name)
    }
    if (type === "audiocreated") { }
  }

  useEffect(() => {
    window.adapter = new communicationAdapter(backendUrl, room, { name, token, accessKey, testMode, testModeData: testMode ? testModeData : {} }, { audio: !audiooff && !canvas }, adapterCallback, isP2p ? "p2p" : "sfu", simulcast, uaLayout, hlsUrl)
    return () => {
      let adapter = window.adapter;
      window.adapter = null;
      adapter.disconnect();
    }
  }, [token])

  if (unAuthorized) {
    return (
        <div>
        {!eventKind &&  <div style={{ minHeight: isFullScreen ? "100vh" : "" }}>
        <Grid container>
          <Grid item sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ marginTop: 200 }}>
              {isFullScreen &&<> <br/><br/></>}
              <Alert severity="error">This class has finished!</Alert>
            </div>
            {/* <center>
              <Button variant="contained" color="primary" onClick={() => setLeft()}>Go back</Button>
            </center> */}
          </Grid>
        </Grid>
      </div>}
        </div>
    )
  }
  const _getFullScreenlayout = () => {
    return <FullScreenLayout
      hlsUrl={hlsUrl}
      eventKind={eventKind}
      room={room}
      destroyEndpoint={destroyEndpoint}
      uaLayout={uaLayout}
      setChat={setChat}
      pollData={pollData}
      setPollData={setPollData}
      accessKey={accessKey}
      token={token}
      quality={quality}
      setQuality={setQuality}
      pdfData={pdfData}
      pdfSlide={pdfSlide}
      setPdfData={setPdfData}
      setPdfSlide={setPdfSlide}
      whiteBoardData={whiteBoardData}
      setWhiteBoardData={setWhiteBoardData}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      theme={theme}
      setLeft={setLeft}
      whiteboard={whiteboard}
      setWhiteboard={setWhiteboard}
      audioPermissionError={audioPermissionError}
      setAudioPermissionError={setAudioPermissionError}
      setVideoPermissionError={setVideoPermissionError}
      videoPermissionError={videoPermissionError}
      cameras={cameras}
      mics={mics}
      sinks={sinks}
      chat={chat}
      name={name}
      someoneIsSharing={someoneIsSharing}
      simulcast={simulcast}
      setVideoStreams={setVideoStreams}
      pinned={pinned}
      solo={solo}
      layout={layout}
      videoStreams={videoStreams}
      localVideoStreams={localVideoStreams}
      setLocalAudioStreams={setLocalAudioStreams}
      setVideoAssignedId={setVideoAssignedId}
      setLocalVideoStreams={setLocalVideoStreams}
      setPinned={setPinned}
      setSolo={setSolo}
      setAudioState={setAudioState}
      setControl
      audioState={audioState}
      videoMuted={videoMuted}
      setVideoMuted={setVideoMuted}
      type={type}
      changeLayout={changeLayout}
      setLeftRotationHack={setLeftRotationHack}
      setRightRotationHack={setRightRotationHack}
      toggleScreenshare={toggleScreenshare}
      showScreenshareButton={!someoneIsSharing.peerId}
      screenShareOn={screenShareOn}
      setVideoDevices={setVideoDevices}
      videoAssignedId={videoAssignedId}
      audioAssignedId={audioAssignedId}
      cameraReplaced={(track, stream) => { }}
      peers={peers}
      messages={messages}
      setMessages={setMessages}
    />
  }

  const _getAroundLayout = () => {
    return <AroundLayout
      type={type}
      whiteboard={whiteboard}
      setWhiteboard={setWhiteboard}
      someoneIsSharing={someoneIsSharing}
      screenShareOn={screenShareOn}
      setVideoStreams={setVideoStreams}
      videoMuted={videoMuted}
      localVideoStreams={localVideoStreams}
      setVideoMuted={setVideoMuted}
      cameras={cameras}
      videoAssignedId={videoAssignedId}
      mics={mics}
      sinks={sinks}
      videoStreams={videoStreams}
      setLocalAudioStreams={setLocalAudioStreams}
      audioState={audioState}
      setAudioState={setAudioState}
      toggleScreenshare={toggleScreenshare}
    />
  }

  const _getUnacademyLayout = () => {
    return <UnacademyLayout />
  }

  if (view === UNACADEMY) {
    return _getUnacademyLayout();
  }

  if (view === FULLSCREEN) {
    return _getFullScreenlayout();
  }
  return _getAroundLayout();
}

export default Stream;
