import { Button, Checkbox, FormControlLabel, IconButton, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { MuteIcon } from './layouts/MuteIcon';
import { MuteVideoIcon } from "./layouts/MuteVideoIcon";
import { FULLSCREEN } from "../constants";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import React from 'react';
import config from "../config";
import { WhiteboardControls } from './layouts/parents/WhiteboardControls';
import { PdfControls } from './PdfControls';
import { CreatePoll } from './CreatePoll';
import { PostAddRounded } from '@material-ui/icons';
function iOS() {

  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
    //@ts-ignore
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const MAX_VIDEOS = config.options.maxCameras;

const useStyles = makeStyles((theme) => ({
  icon: {
    //@ts-ignore
    color: ({theme}) => theme === "dark" ? "white" : "#192a56",
    fontSize: 30, 
    marginRight: 5
  },
  btn: {
    background: "#ecf0f1",
    width: 65,
    height: 50,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#dfe4ea"
    },
    fontSize: 20
  }
}))
export const ControlsNew = ({
  eventKind,
  type,
  theme,
  pollData,
  setPollData,
  setVideoStreams,
  audioState,
  setWhiteboard,
  audioAssignedId,
  setAudioState,
  whiteboard,
  setVideoMuted,
  videoMuted,
  cameras,
  mics,
  videoAssignedId,
  peerId,
  screenShareOn,
  session_creator,
  localVideoStreams,
  toggleScreenshare,
  changeLayout,
  setLeftRotationHack,
  setRightRotationHack,
  setLocalAudioStreams,
  showScreenshareButton,
  uaLayout,
  pdfData,
  pdfSlide,
  setPdfData,
  setPdfSlide,
  accessKey,
  token,
  whiteBoardData,
  setWhiteBoardData,
  currentPage,
  setCurrentPage,
  activePdf,
  setActivePdf,
  fetchPdfs,
  isPdfLoading
}) => {
  const classes = useStyles({theme});
  // const startCam = () => {
  //   let cameraSet = false;
  //   videoDevices.map(device => {
  //     if (cameraSet) {
  //       return;
  //     }
  //     Object.keys(window.adapter.videoElProps).map(x => {
  //       const oldVideoConstraints = window.adapter.videoElProps[x]?.constraints?.video;
  //       if (!oldVideoConstraints) {
  //         return;
  //       }
  //       let oldVideoDeviceId = window.adapter.videoElProps[x]?.constraints?.video?.deviceId?.exact || window.adapter.videoElProps[x]?.constraints?.video?.deviceId
  //       if (device.deviceId !== oldVideoDeviceId) {
  //         window.adapter.createVideoProducer({ video: { deviceId: device.deviceId } })
  //         cameraSet = true;
  //       }
  //     })
  //   })
  //   if (!cameraSet) {
  //     window.adapter.createVideoProducer({ video: { deviceId: videoDevices[0].deviceId } })
  //   }
  // }
  const isProducer = type === "producer";
  const addPage = () => {
    window.adapter.addPage({background : "white", strokes: []})
  }
  return (
    <div>{/*
    // @ts-ignore */}
    <center>
      {/*
    // @ts-ignore */}
      {isProducer && <MuteIcon
        assignedId={audioAssignedId}
        mics={mics}
        setLocalAudioStreams={setLocalAudioStreams}
        audioState={audioState}
        peerId={"local"}
        setAudioState={setAudioState}
      />}
      {/*
    // @ts-ignore */}
      {isProducer && <MuteVideoIcon
        assignedId={videoAssignedId}
        cameras={cameras}
        setVideoStreams={setVideoStreams}
        videoMuted={videoMuted}
        setVideoMuted={setVideoMuted}
        localVideoStreams={localVideoStreams}
      />}
      {!uaLayout && isProducer && !eventKind &&  <WhiteboardControls
        theme={theme}
        view={FULLSCREEN}
        whiteboard={whiteboard}
        setWhiteboard={setWhiteboard}
      />}
      {isProducer && !eventKind &&  <PdfControls
        theme={theme}
        view={FULLSCREEN}
        pdfData={pdfData}
        pdfSlide={pdfSlide}
        setPdfData={setPdfData}
        setPdfSlide={setPdfSlide}
        accessKey={accessKey}
        token={token}
        activePdf={activePdf}
        setActivePdf={setActivePdf}
        fetchPdfs={fetchPdfs}
        isPdfLoading={isPdfLoading}
      />}
      {(isProducer && showScreenshareButton) && <span>
        <IconButton className={classes.btn} onClick={toggleScreenshare} style={{border: "1px solid #ced6e0", background: "#f1f2f6", borderRadius: 7}}>
        <div>
        <div style={{marginBottom: -4}}>{
        screenShareOn ? <StopScreenShareIcon style={{color: "#27ae60"}} /> : <ScreenShareIcon  />
        }</div>
          <div style={{fontSize: 10, marginBottom: -5, color: "black" }}>
            {!screenShareOn ? "Screenshare" : "Stop"}
          </div>
        </div>
        </IconButton>
      </span>}
      {/*
    // @ts-ignore */}
      {isProducer && <CreatePoll
        accessKey={accessKey}
        token={token}
        pollData={pollData}
        setPollData={setPollData}
      />}


    {(isProducer) && <span>
        <IconButton className={classes.btn} onClick={addPage} style={{border: "1px solid #ced6e0", background: "#f1f2f6", borderRadius: 7, padding:"5px"}}>
        <div>
        <div style={{marginBottom: -4}}><PostAddRounded /></div>
          <div style={{fontSize: 11, marginBottom: -5, color: "black" }}>
            Add page
          </div>
        </div>
        </IconButton>
      </span>}
{/*
    // @ts-ignore */}
    </center>
    </div>
  )
}
