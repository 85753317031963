import { IconButton, makeStyles, CircularProgress, Typography, Popover, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import PollIcon from '@material-ui/icons/Poll';
import axios from "axios";
import config from "../config";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: "relative",
    display: "inline-block",
    '&:hover': {
      background: "#ced6e0"
    }
  },
  icon: {
    //@ts-ignore
    color: ({theme}) => theme === "dark" ? "white" : "#192a56",
    fontSize: 25, 
    marginRight: 5
  },
  btnSmall: {
    background: "black",
    width: 14,
    height: 14,
    padding: 10,
    "border-top-left-radius": 5,
    "border-bottom-left-radius": 5,
    "border-top-right-radius": 5,
    "border-bottom-right-radius": 5,
    //@ts-ignore
    padding: 14
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
  btn: {
    background: "#ecf0f1",
    width: 65,
    height: 50,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#dfe4ea"
    },
    fontSize: 20
  }
}));

export const CreatePoll = ({ 
  theme,
  accessKey,
  poll,
  token,
  pollData,
  setPollData
}) => {
  const classes = useStyles({theme});
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [description, setDescription] = React.useState("");
  const [time, setTime] = React.useState();
  const [option1, setOption1] = React.useState("A");
  const [option2, setOption2] = React.useState("B");
  const [option3, setOption3] = React.useState("C");
  const [option4, setOption4] = React.useState("D");
  
  const sendRequest = (file) => {
    setLoading(true)
  };

  const handleClick = (event) => {
    if (!pollData?.poll_id) {
      setAnchorEl(event.currentTarget);
    }  else {
      // current state is ended
      setPollData({
        poll_id: null,
      })
      window.adapter.setPollData({
        poll_id: null,
      })
    }
  };

  const createPoll = () => {
      setLoading(true);
      axios.post(`${config.cloudFnUrl}/createPoll`, {
        accessKey: accessKey,
        token: token,
        description,
        options: [option1, option2, option3, option4]
      })
      .then(response => {
        setLoading(false);
        setAnchorEl(null);
        // alert(response.data.poll_id);
        if (response.data.poll_id) {
          window.adapter.setPollData({
            poll_id: response.data.poll_id,
            state: "ongoing",
          })
          setPollData({
            poll_id: response.data.poll_id,
            state: "ongoing",
          });
        }
      })
  }

  return (
    <>
      <IconButton className={classes.btn} style={{border: "1px solid #ced6e0", background: "#f1f2f6", borderRadius: 7}} onClick={handleClick} size="small">
        <div>
        <div style={{marginBottom: 0}}>
          {!loading && <PollIcon style={{fontSize: 20, color: poll ? "#27ae60" : ""}} />}
          {loading && <CircularProgress size={15} style={{fontSize: 12, color: poll ? "#27ae60" : ""}} />}
          </div>
          <div style={{fontSize: 11, marginBottom: -5,  color: "black" }}>
            {!pollData.poll_id && "Poll"}
            {pollData.poll_id  && "End Poll"}
          </div>
        </div>
      </IconButton>
      <Popover
        style={{minWidth: "400px"}}
          //@ts-ignore
        placement="top"
        fullWidth
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{padding: 20}}>
          {/*
          //@ts-ignore*/}
        <center><Typography variant="h6">Create Poll</Typography></center>
        <TextField
          value={description}
          onChange={(e) => setDescription((e.target.value))}
          size="small"
          id="outlined-full-width"
          label="Question"
          style={{ margin: 8 }}
          placeholder="Question"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          disabled={loading}
        />
        <div>
        <TextField
          value={option1}
          onChange={(e) => setOption1((e.target.value))}
          size="small"
          id="outlined-full-width"
          label="Option A"
          style={{ margin: 8 }}
          placeholder="Option A"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          disabled={loading}
        />
        <TextField
          value={option2}
          onChange={(e) => setOption2((e.target.value))}
          size="small"
          id="outlined-full-width"
          label="Option B"
          style={{ margin: 8 }}
          placeholder="Option B"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          disabled={loading}
        />
        </div>
        <div>
        <TextField
          value={option3}
          onChange={(e) => setOption3((e.target.value))}
          size="small"
          id="outlined-full-width"
          label="Option C"
          style={{ margin: 8 }}
          placeholder="Option C"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          disabled={loading}
        />
        <TextField
          disabled={loading}
          value={option4}
          onChange={(e) => setOption4((e.target.value))}
          size="small"
          id="outlined-full-width"
          label="Option D"
          style={{ margin: 8 }}
          placeholder="Option D"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
        </div>
        {/* <TextField
          value={time}
          onChange={(e) => setTime((e.target.value))}
          size="small"
          id="outlined-full-width"
          label="Timer (in minutes)"
          style={{ margin: 8 }}
          placeholder="Time in minutes"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputMode="numeric"
          variant="outlined"
          disabled={loading}
        /> */}
          {/*
          //@ts-ignore*/}
        <center>
          <Button disabled={loading || (!option1 && !option2 && !option3 && !option4)} color="primary" variant="contained" size="small" onClick={() => createPoll()}>Create a poll</Button>
          {loading && <CircularProgress size={18} style={{marginLeft: 5, marginTop: 10}} />}
          {/*
          //@ts-ignore*/}
        </center>
        </div>
      </Popover>
    </>
  )
}
