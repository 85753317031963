import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  roundContainer: {
    display: "flex"
  },
  localVideo: {
    // @ts-ignore
    marginLeft: ({ height }) => -1 * (height || 200) * 1.3 / 4,
    // @ts-ignore
    height: ({ height }) => height || 300,
    // borderRadius: "500px",
    // "-webkit-border-radius": "500px",
    // "-moz-border-radius": "500px",
  },
  roundContainerInnerMuted: {
    // @ts-ignore
    width: ({ height }) => height || 200,
    // @ts-ignore
    height: ({ height }) => height || 200,
    // borderRadius: "500px",
    // "-webkit-border-radius": "500px",
    // "-moz-border-radius": "500px",
    "overflow": "hidden",
    "background": "black",
  },
  roundContainerInner: {
    // width: ({ height }) => height || 200,
    display: "block",
    // @ts-ignore
    height: ({ height }) => height || 200,
    // borderRadius: "500px",
    // "-webkit-border-radius": "500px",
    // "-moz-border-radius": "500px",
    "overflow": "hidden",
    "background": "black",
    color: "white",
    zIndex: 9
  }
}))

export const IndividualVideoCircular = (props) => {
  const videoRef = useRef(null)
  const classes = useStyles({ height: props.height });
  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    videoRef.current.srcObject = props.stream;
    videoRef.current.play();

  }, [videoRef, props.stream])

  return (
    <div className={!props.isMuted ? classes.roundContainerInner : classes.roundContainerInnerMuted} style={{
      boxSizing: "border-box",
      MozBoxSizing: "border-box",
      WebkitBoxSizing: "border-box",
      border: props.isActive ?  "5px solid #2ecc71" : "0px solid #2ecc71",
      // transition: "border 0.2s cubic-bezier(0.4,0.0,0.2,1)",
      // transform: "translate3d(0, 0, 0)"
    }}>
      <img width="60%" style={{ display: !props.isMuted ? "none" : "", "marginLeft": "20%", marginTop: "20%" }} src={`https://ui-avatars.com/api/?name=${props.peerId === "local" ? props.myName : props.name}&background=2c3e50&color=ecf0f1&rounded=true`} />
      <video className={classes.localVideo} ref={videoRef} autoPlay={true} playsInline={true} style={{ visibility: props.isMuted ? "hidden" : "visible" }} />
    </div>
  )
}
