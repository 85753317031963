import { IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginBottom: -60,
    background: "#ecf0f1",
    height: 50,
    border: "0px",
    borderRadius: "2px",
    marginRight: theme.spacing(1),
    backgroundColor: "white"
  }
}))

export const IndividualVideo = (props) => {
  const videoRef = useRef(null)
  const [marginTop, setMarginTop] = useState(56.25)
  const classes = useStyles();

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    videoRef.current.srcObject = props.stream;

    videoRef.current.play();
    function listener() {
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        setMarginTop(56.25 + 100 * (videoHeight - videoWidth * 1 / 1.77) / (2 * videoHeight))
      }
    }
    videoRef.current.addEventListener("resize", listener)
    return () => {
      if (videoRef.current)
        videoRef.current.removeEventListener("resize", listener)
    }
  }, [videoRef, props.stream])

  return (
    <div style={{ color: "white" }}>
      {/* {!props.hideName && <IconButton disableRipple className={classes.btn} style={{ backgroundColor: 'transparent', visibility: props.mouseNotMoved ? "visible" : "hidden", }}>
        <Typography variant="h6" style={{ marginRight: 15, background: "white", borderRadius: 20, paddingLeft: 10, paddingRight: 10 }}>
          {props.name}
        </Typography>
        <span style={{ zIndex: 100 }}>
          <AdminIcons {...props} />
        </span>
        {props.peerId === "local" && <ScoreIcon
          score={props.score}
        />}
      </IconButton>} */}
      <div style={{ position: "relative", height: 0, paddingTop: "56.25%", overflow: "hidden", background: "black", border: props.isActive ? "2px solid #1abc9c" : "", borderRadius: props.small ? 5 : 20, margin: 0, 
      boxSizing: "border-box",
      "MozBoxSizing": "border-box",
      "WebkitBoxSizing": "border-box"}}>
      <div style={{position: "absolute",
        top: "10px",
        left: "14px",
        fontSize: "12px",
        color: "white",
        fontWeight: "600",
        textShadow: "0px 0px 5px #000",}}>
        {props.name}
      </div>
        <div style={{ marginTop: "-" + marginTop + "%", visibility: props.isMuted ? "hidden" : "visible" }}>
          <video style={{ width: "100%",}} ref={videoRef} autoPlay={true} playsInline={true} />
        </div>
        <div style={{
          marginTop: "-" + marginTop * 0.62 + "%",
          width: "100%",
          visibility: !props.isMuted ? "hidden" : "visible"
        }}>
          {/*
    // @ts-ignore */}
          <center>
            <div style={{  }}>
              <img width="20%" src={`https://ui-avatars.com/api/?name=${props.peerId === "local" ? props.myName : props.name}&background=2c3e50&color=ecf0f1&rounded=true`} />
            </div>
            {/*
    // @ts-ignore */}
          </center>
        </div>
      </div>
    </div>
  )
}
