import openSocket from "socket.io-client";
import { v4 as uuid } from 'uuid';
import config from "../config";
import P2pDataAdapter from "./p2pAdapter";
import * as mediasoupClient from "mediasoup-client";
// const mediasoupClient = require("../mediasoup-client/lib/index.ts");
let ASPECT_RATIO = {
  "0": { height: 180, width: 320 },
  "1": { height: 360, width: 640 },
  "2": { height: 720, width: 1280 },
  "3": { height: 1280, width: 1920 },
}
const peerToBitrateMapping = config.peerToBitrateMapping;







let WEBCAM_SIMULCAST_ENCODINGS =
  [
    // { scaleResolutionDownBy: 4, maxBitrate: 500000 },
    // { scaleResolutionDownBy: 1.5, maxBitrate: 580000 },
    { scaleResolutionDownBy: 1, maxBitrate: 980000 }
  ];

const peerToWidthMapping = {
  "1": null,
  "2": null,
  "3": null,
  "4": null,
  "5": null,
  "6": null,
  "7": null,
  "8": 400,
  "9": 320,
  "10": 320
}

export default class CommunicationAdapter {
  hlsUrl: string;
  backendUrl: string;
  debounceSpatialLayerInterval: any;
  simulcast: any;
  webrtcType: any;
  aspectRatio: any;
  initWhiteboardPending: any;
  turnCreds: any;

  botDataProducer: any;
  consumerTransport: any;
  producerTransport: any;
  audioTransmitting: any;
  filters: any;
  brightness: any;
  videoElProps: any;
  socket: any;
  peerMediaHandler: any;
  room: any;
  metadata: any;
  mediaProducers: any;
  mediaConsumers: any;
  producerParams: any;
  callback: any;
  webRTCDevice: any;
  p2pAdapter: P2pDataAdapter;
  emojiCallback: any;

  // @ts-ignore
  constructor(backendUrl, room, metadata, producerParams, callback, webrtcType, simulcast, isRecordingPeer, uaLayout, hlsUrl=window.hlsUrl) {
    this.hlsUrl = hlsUrl;
    this.backendUrl = backendUrl;
    this.debounceSpatialLayerInterval = null;
    if (uaLayout) {
      WEBCAM_SIMULCAST_ENCODINGS =
        [
          // { scaleResolutionDownBy: 4, maxBitrate: 500000 },
          // { scaleResolutionDownBy: 2, maxBitrate: 50000 },
          { scaleResolutionDownBy: 1, maxBitrate: 100000 }
        ];
      ASPECT_RATIO = {
        "0": { height: 180, width: 320 },
        "1": { height: 150 * 0.40, width: 266 * 0.40 },
        "2": { height: 720, width: 1280 },
        "3": { height: 1280, width: 1920 },
      }
    }
    this.simulcast = simulcast;
    this.webrtcType = webrtcType || "sfu";
    this.aspectRatio = ASPECT_RATIO;
    this.initWhiteboardPending = false;
    this.turnCreds = {
      username: "username",
      password: "password"
    }
    this.botDataProducer = null;
    this.consumerTransport = undefined
    this.producerTransport = undefined
    this.audioTransmitting = false;
    this.filters = {};
    this.brightness = {};
    this.videoElProps = {};
    this.socket = undefined
    this.peerMediaHandler = undefined
    this.room = null;
    this.metadata = metadata;
    this.mediaProducers = [];
    this.mediaConsumers = [];
    this.producerParams = producerParams; // {audio: true, video: true}...
    this.callback = callback || (() => { });
    try {
      if (!this.hlsUrl) {
        console.error(this.hlsUrl);
        console.error("inside")
        console.log(!this.hlsUrl)
        this.webRTCDevice = new mediasoupClient.Device();
      }
    } catch (err) {
      console.log(err);
      console.log("error while loading mediasoup client device");
      // alert("Failed to load WebRTCDevice", err);
    }

    // @ts-ignore
    this.socket = openSocket(`${backendUrl}`, { transport: ['websocket'], query: `session_id=${room}` });
    this.p2pAdapter = new P2pDataAdapter(this.socket, callback, producerParams, ASPECT_RATIO, room);
    this.socket.on("disconnect", () => {
      this.callback({
        type: "signalDisconnected",
        data: {
          videos: Object.keys(this.videoElProps).map(x => ({
            constraints: this.videoElProps[x].constraints,
            stream: this.videoElProps[x].videoEl.srcObject,
            type: this.videoElProps[x].type
          }))
        }
      })
    })
    this.socket.on('connect_error', () => {
      this.callback({
        type: "signalDisconnected",
        data: {
          videos: Object.keys(this.videoElProps).map(x => ({
            constraints: this.videoElProps[x].constraints,
            stream: this.videoElProps[x].videoEl.srcObject,
            type: this.videoElProps[x].type
          }))
        }
      })
    });
    this.socket.on("connect", () => {
      this.socket.emit("initialize", {
        room,
        metadata,
        isRecordingPeer,
        isOrchestrated: !(new URLSearchParams(window.location.search).get("notOrchestrated"))
      })
    })

    this.socket.on("unauthorized", (data) => this.callback({
      type: "unauthorized",
      data: {
        message: data.message
      }
    }))
    this.socket.on("identityAck", this.identityReceived.bind(this))
    this.socket.on("createdTransports", this.createdTransports.bind(this));
    this.socket.on("consumeMedia", this.consumeMedia.bind(this));
    this.socket.on("destroyMedia", this.destroyMedia.bind(this));
    this.socket.on("peerDisconnect", (data) => {
      // this.mediaConsumers.filter(x => x.peerId === data.peerId).map(x => {
      // if (x.isDesktop) {
      // the left peer was sharing video, now we can share the right one
      // this.normalizeOutgoingVideos();
      // }
      // })
      this.mediaConsumers = this.mediaConsumers.filter(x => x.peerId !== data.peerId);
      this.consolidateOutgoingVideoResolutions()
      this.callback({
        type: "disconnect",
        data
      })
    })

    this.socket.on("metaEvent", (data) => {
      if (data.type === "emj") {
        if (this.emojiCallback) {
          this.emojiCallback(data);
        }
      } else {
        this.callback({
          type: data.type,
          data
        })
      }
    })

    this.socket.on("producerScore", this.producerScoreHandler.bind(this))
    this.socket.on("consumerScaore", this.consumerScoreHandler.bind(this))
  }

  consumerScoreHandler({ score, assignedId }) {
    const mediaConsumer = this.mediaConsumers.find(x => x.assignedId === assignedId)
    if (!mediaConsumer || mediaConsumer.isDesktop) return;
    if (score < 2) {
      // this.socket.emit("toggleIncomingMedia", {
      //   type: "pause",
      //   kind: "video",
      //   assignedId
      // });
    } else {
      // this.socket.emit("toggleIncomingMedia", {
      //   type: "resume",
      //   kind: "video",
      //   assignedId
      // });
    }
  }

  producerScoreHandler({ scores, assignedId }) {
    const score = scores.reduce(function (avg, { score }, _, { length }) {
      return avg + score / length;
    }, 0);
    this.callback({
      type: "producerScore",
      data: {
        assignedId,
        score
      }
    })
    const mediaProducer = this.mediaProducers.find(x => x.assignedId === assignedId);
    const producer = mediaProducer?.producer;
    if (producer) {
      if (score < 4) {
        // producer.setMaxSpatialLayer(1)
      }
      //  else if (score < 9) {
      //   producer.setMaxSpatialLayer(2)
      // }
      else {
        // producer.setMaxSpatialLayer(2)
      }
    }
  }

  _adaptiveEnabled() {
    return config.options.adaptive
  }

  _getIceServers() {
    return config.options.dontuseturn ? [] : [{
      username: "",
      password: "",
      urls: `stun:${config.turnServerUrl}`
    }, {
      username: this.turnCreds.username,
      credential: this.turnCreds.password,
      urls: `turn:${config.turnServerUrl}`
    }]

  }

  sendLayoutToRecorder(props) {
    this.socket.emit("intimateRecordingPeer", props)
  }

  pauseConsumer({ assignedId }) {
    this.mediaConsumers.map(mediaConsumer => {
      if (mediaConsumer.assignedId === assignedId) {
        mediaConsumer.consumer.pause();
      }
    })
  }

  resumeConsumer({ assignedId }) {
    this.mediaConsumers.map(mediaConsumer => {
      if (mediaConsumer.assignedId === assignedId) {
        mediaConsumer.consumer.resume();
      }
    })
  }

  pauseAllConsumers() {
    this.mediaConsumers.map(mediaConsumer => {
      mediaConsumer.consumer.pause();
    })
    this.socket.emit("toggleIncomingMedia", {
      type: "pause",
      kind: "video"
    })
  }

  resumeAllConsumers() {
    this.mediaConsumers.map(mediaConsumer => {
      mediaConsumer.consumer.resume();
    })
    this.socket.emit("toggleIncomingMedia", {
      type: "resume",
      kind: "video"
    })
  }

  registerEmojiCallback(fn) {
    this.emojiCallback = fn;
  }

  disconnect() {
    this?.p2pAdapter?.destroy();
    this.socket.disconnect();
    if (this.producerTransport) {
      this.producerTransport.close();
    }
    if (this.consumerTransport) {
      this.consumerTransport.close();
    }
    this.producerTransport = null;
    this.consumerTransport = null;
  }

  emit(msg, socketId) {
    this.socket.emit("metaEvent", {
      ...msg,
      to: socketId
    })
  }

  /*
   * Invite the user to become a producer
   */
  inviteUser(peerId) {
    this.emit({
      type: "makeProducer"
    }, peerId);
    this.callback({
      type: "peerUpdated",
      data: {
        peerId,
        kind: "producer"
      }
    })
  }

  removeUserAsProducer(peerId) {
    this.emit({
      type: "makeConsumer"
    }, peerId);
    this.callback({
      type: "peerUpdated",
      data: {
        peerId,
        kind: "consumer"
      }
    })
  }

  muteUser(socketId) {
    this.emit({
      type: "muteUser"
    }, socketId)
  }

  muteToggled(isMuted) {
    this.socket.emit("toggleAudioMute", {
      audioMuted: isMuted
    });
  };

  registerWhiteboardCallback(fn) {
    // @ts-ignore
    this.whiteboardCallback = fn;
  }

  stopMediaItems() {

    Object.keys(this.videoElProps).map(assignedId => {
      let srcObject = this.videoElProps[assignedId]?.videoEl?.srcObject;
      srcObject.getTracks().forEach(t => t.stop());
    })
    // @ts-ignore
    let srcObject = this.audioStream;
    srcObject.getTracks().forEach(t => t.stop);
  }

  clearWhiteboard() {
    this.sendViaBotDataChannel(JSON.stringify({ "type": "wbClear", data: { clear: true } }));
  }

  initWhiteboard() {
    // @ts-ignore
    if (!this.consumerTransport || !this.botDataChannel) {
      this.initWhiteboardPending = true;
      return;
    }
    this.socket.emit("initWhiteboard")
  }

  videoMuteToggled(isMuted) {
    this.socket.emit("toggleVideoMute", {
      videoMuted: isMuted
    });
  }

  muteAll() {
    // @ts-ignore
    this.emit({
      type: "muteUser"
    })
  };

  async identityReceived(data) {
    // @ts-ignore
    this.id = data.id;
    if (data.forceSfu) {
      this.webrtcType = "sfu";
    }
    if (data.whiteboardEnabled) {
      this.callback({
        type: "updateWhiteboard",
        data: { value: true }
      })
    }
    if (data.pollData) {
      this.callback({
        type: "updatePollData",
        data: {
          pollData: data.pollData
        }
      })
    }
    if (data.pdfSlide) {
      this.callback({
        type: "updatePdfSlide",
        data: {
          pdfSlide: data.pdfSlide,
          pdfData: data.pdfData,
        }
      })
    }
    // @ts-ignore
    this.name = data.name;
    this.callback({
      type: "setname",
      data: {
        name: data.name
      }
    })

    this.callback({
      type: "identity",
      data: {
        id: data.id,
        broadcaster: data.broadcaster,
        peers: data.peers,
        room: data.room,
        type: data.type,
      }
    })

    // this.turnCreds = data.turnCreds;
    this.turnCreds = {
      username: "username",
      password: "password"
    }
    // @ts-ignore
    this.type = data.type;
    if (this.webrtcType === "p2p") {
      this.p2pAdapter.identityReceived(data, this._getIceServers());
      return;
    }

    if (!this.hlsUrl) {
      // only if we're not using hls should we use webrtc
      try {
        await this.webRTCDevice.load({
          routerRtpCapabilities: data.roomRTPCapabilities
        });
      } catch (e) {
        console.log(e);
        console.log(e);
        console.log(data.roomRTPCapabilities);
      }
      this.socket.emit("createTransports", {
        numStreams: this.webRTCDevice.sctpCapabilities.numStreams,
        rtpCapabilities: this.webRTCDevice.rtpCapabilities
      })
    }
  }

  async createdTransports(transports) {
    // @ts-ignore
    this.transports = transports;
    this.consumerTransport = await this.webRTCDevice.createRecvTransport(
      {
        id: transports.consumer.id,
        iceParameters: transports.consumer.iceParameters,
        iceCandidates: transports.consumer.iceCandidates,
        dtlsParameters: transports.consumer.dtlsParameters,
        sctpParameters: transports.consumer.sctpParameters,
        iceServers: this._getIceServers()
      });
    const self = this;
    this.consumerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      self.socket.emit('connectTransport', { direction: self.consumerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    // @ts-ignore
    if (this.type === "producer") {
      this.createProducerTransport();
    }

    if (transports.botDataChannel) {
      self.consumerTransport.consumeData(transports.botDataChannel)
        .then((newConsumer) => {
          // @ts-ignore
          this.botDataChannel = newConsumer;
          if (this.initWhiteboardPending) {
            window.setTimeout(() => {
              this.socket.emit("initWhiteboard")
            }, 500);
          }
          // @ts-ignore
          this.botDataChannel.on("message", data => {
            try {
              let data2 = JSON.parse(data);
              if (["wb", "wbClear", "replaceShape", "pageChanged", "changeBackground", "addPage", "initialize", "removeStrokes"].includes(data2.type)) {
                // @ts-ignore
                this.whiteboardCallback(data2);
                return;
              }
            }
            catch (e) {
              console.error("Error parsing bot message", e);
            }
            this.callback({
              type: "botmessage",
              data: data
            })
          });
        })
    }

    this.callback({
      type: "transportsCreated"
    })
  }

  async makeProducer() {
    // @ts-ignore
    if (this.type === "producer") {
      return;
    }
    // @ts-ignore
    this.type = "producer";
    if (!this.producerTransport) {
      this.createProducerTransport();
    }
    // @ts-ignore
    this.createVideoProducer({});
  }

  async unMakeProducer() {
    // @ts-ignore
    this.type = "consumer";
    this.destroyProducerTransport();
  }

  async createProducerTransport() {
    const self = this;
    // @ts-ignore
    const transports = this.transports;
    if (!transports) {
      console.error("No transports found, err");
      return;
    }

    this.producerTransport = await this.webRTCDevice.createSendTransport(
      {
        id: transports.producer.id,
        iceParameters: transports.producer.iceParameters,
        iceCandidates: transports.producer.iceCandidates,
        dtlsParameters: transports.producer.dtlsParameters,
        sctpParameters: transports.producer.sctpParameters,
        iceServers: this._getIceServers()
      });
    this.producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      self.socket.emit('connectTransport', { direction: self.producerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    this.producerTransport.on(
      "producedata",
      async (
        { sctpStreamParameters, label, protocol, appData },
        callback,
        errback) => {
        // Send dataProducer data to server
        self.socket.emit('produceData', {
          producerOptions: {
            sctpStreamParameters, label, protocol, appData
          }
        }, function (params) {
          callback({ id: params.id });
        });
      }
    );
    this.producerTransport.on('produce', async function ({ kind, rtpParameters, appData }, callback, errback) {
      //Send producer data to server
      self.socket.emit('produceMedia', {
        producerOptions: {
          kind: kind,
          rtpParameters: rtpParameters,
          appData: appData
        }
      }, function (params) {
        self.mediaProducers = self.mediaProducers.map(producerProps => {
          if (producerProps.assignedId === params.assignedId) {
            return {
              ...producerProps,
              id: params.id
            }
          }
          return producerProps;
        })
        callback({ id: params.id });
      })
    })

    // if (this.producerParams.audio) {
    this.createAudioProducer(
      { audio: true }
    );
    // } else {
    //   const self = this;
    //   self.muteToggled(true);
    // }
    this.createBotDatachannel();
  }

  destroyProducerTransport() {

    this.mediaProducers.map(({ assignedId }) => {
      this.destroyMediaProducer({ assignedId });
    })

  }

  async createBotDatachannel() {
    const producer = await this.producerTransport.produceData({
      ordered: false,
      maxRetransmits: 0,
      label: "bot",
      appData: { source: "bot" }
    });
    this.botDataProducer = producer;
  }

  consolidateIncomingVideos(assignedIdToLayerMapping) {
    if (!this.simulcast) {
      return;
    }
    return;
    // make them stick to 0 or 1 since now we're testing with 2 simulcast layers
    Object.keys(assignedIdToLayerMapping).map(x => {
      assignedIdToLayerMapping[x] = Math.min(1, assignedIdToLayerMapping[x]);
    })
    if (this.debounceSpatialLayerInterval) {
      window.clearTimeout(this.debounceSpatialLayerInterval);
    }
    this.debounceSpatialLayerInterval = window.setTimeout(() => {
      this.socket.emit("changeLayerConfiguration", {
        assignedIdToLayerMapping
      })
    })
  }

  setWhiteboardState(value) {
    // @ts-ignore
    this.emit({
      type: "updateWhiteboard",
      value
    });
  }

  raiseHand(value, peerId) {
    // @ts-ignore
    peerId = peerId || this.id;
    // value represents whether we want to raise hand (true) or turn it down (False);
    // @ts-ignore
    this.emit({
      type: "handRaised",
      peerId,
      value
    });
    this.callback({
      type: "handRaised",
      data: {
        peerId,
        value
      }
    })
  }

  setPdfState(pdfSlide, pdfData) {
    // @ts-ignore
    this.emit({
      type: "updatePdfSlide",
      pdfSlide,
      pdfData,
    });
  }

  setPollData(pollData) {
    // @ts-ignore
    this.emit({
      type: "updatePollData",
      pollData
    })
  }

  addPage(page) {
    // @ts-ignore
    // this.emit({
    //   type: "addPage",
    //   page
    // })
    this.sendViaBotDataChannel(JSON.stringify({
      type: "addPage",
      page
    }))
  }

  changePage(pageNo) {
    // @ts-ignore
    this.sendViaBotDataChannel(JSON.stringify({
      type: "pageChanged",
      currentPage: pageNo
    }))
  }
  changeBackground(color) {
    // @ts-ignore
    this.sendViaBotDataChannel(JSON.stringify({
      type: "changeBackground",
      background: color
    }))
  }

  sendViaBotDataChannel(msg) {
    if (!this.botDataProducer || this.botDataProducer.readyState !== "open") {
      console.warn("Data not sent, datachannel not open yet");
      return;
    }
    if (!msg || msg.length === 0) {
      console.warn("No data to send");
      return;
    }
    this.botDataProducer.send(msg)
  }

  destroyMediaProducer({ assignedId }) {
    this.socket.emit("destroyMedia", {
      assignedId
    })
    const mediaProducer = this.mediaProducers.find(x => x.assignedId === assignedId)
    // if (mediaProducer.isDesktop) {
    //   this.normalizeOutgoingVideos();
    // }
    this.mediaProducers = this.mediaProducers.filter(x => x.assignedId !== assignedId);
    this.consolidateOutgoingVideoResolutions()
    if (this.videoElProps[assignedId]) {
      this.videoElProps[assignedId].videoEl.srcObject.getTracks().map(t => t.stop());
      delete this.videoElProps[assignedId]
      const self = this;
      Object.keys(this.videoElProps).map(x => {
        // @ts-ignore
        self.mainVideoEl = this.videoElProps[x].videoEl;
      })
    }

    this.callback({
      type: "destroyMedia",
      data: {
        assignedId
      }
    })
  }
  getStreamCount() {
    let ougoingVideoStreamCount = this.mediaProducers.filter(x => x.producer.kind === "video").length
    let incomingVideoStreamCount = this.mediaConsumers.filter(x => x.consumer.kind === "video").length
    const count = (ougoingVideoStreamCount + incomingVideoStreamCount)
    return count;
  }
  async consolidateOutgoingVideoResolutions() {
    let count = this.getStreamCount();
    if (this.mediaConsumers.find(x => x.isDesktop) || this.mediaProducers.find(x => x.isDesktop)) {
      // a desktop video is being shared so dont change resolutions
      return;
    }
    if (peerToWidthMapping[count]) {
      // @ts-ignore
      this.downgradeOutgoingVideos(peerToWidthMapping[count])
    } else {
      this.downgradeOutgoingVideos(null, 1)
    }
  }
  downgradeOutgoingVideos(desiredWidth = 300, desiredRatio) {
    Object.keys(this.videoElProps).map(assignedId => {
      let { type } = this.videoElProps[assignedId];
      if (type === "desktop") {
        // dont downgrade streaming screen video
        return;
      }
      this.downgradeOutgoingVideo(assignedId, desiredWidth, desiredRatio)
    })
  }
  downgradeOutgoingVideo(assignedId, desiredWidth = 300, ratio) {
    if (!this._adaptiveEnabled()) {
      return;
    }
    let { constraints } = this.videoElProps[assignedId];
    ratio = ratio || (constraints?.video?.width || 640) / desiredWidth;
    let count = this.getStreamCount();
    this.updateRtpParams(assignedId, {
      scaleResolutionDownBy: Math.max(ratio, 1),
      maxBitrate: 1000 * 1000 * (peerToBitrateMapping[count] || 1)
    })
  }
  normalizeOutgoingVideos() {
    Object.keys(this.videoElProps).map(assignedId => {
      let count = this.getStreamCount();
      this.updateRtpParams(assignedId, {
        scaleResolutionDownBy: 1,
        maxBitrate: 1000 * 1000 * (peerToBitrateMapping[count] || 1)
      })
    })
  }
  updateRtpParams(assignedId, params) {
    if (this.webrtcType === "p2p") {
      this.p2pAdapter.updateRtpParams(assignedId, params);
    } else {
      this.mediaProducers.map(mp => {
        if (mp.assignedId === assignedId) {
          mp.producer.setRtpEncodingParameters(params);
        }
        return ""
      })
    }
  }
  replaceTrack({ assignedId, track, constraints, stream, isAudio }) {
    // @ts-ignore
    if (this.mainVideoEl) {
      if (!isAudio) {
        // @ts-ignore
        this.mainVideoEl.srcObject.getTracks().forEach(t => {
          t.stop()
        });
      }
      if (this.webrtcType === "p2p") {
        if (!isAudio)
            // @ts-ignore
          this.mainVideoEl.srcObject.addTrack(track);// so that this track gets stopped next time the user changes their device
        this.p2pAdapter.replaceTrack({ assignedId, track, constraints, stream })
      } else {
        if (!isAudio)
            // @ts-ignore
          this.mainVideoEl.srcObject = stream;
        this.mediaProducers.map(mp => {
          if (mp.assignedId === assignedId) {
            mp.producer.replaceTrack({ track });
          }
          return ""
        })
      }
    }
    if (!isAudio && this.videoElProps[assignedId] && constraints) {
      this.videoElProps[assignedId].constraints = {
        video: {
          ...this.videoElProps[assignedId].constraints.video,
          ...constraints
        }
      }
      this.videoElProps[assignedId].videoEl = { srcObject: stream }
    }
    this.callback({
      type: "replacedLocalTrack",
      data: {
        assignedId,
        track,
        stream
      }
    })
  }
  changeAspectRatio({ assignedId, ratio }) {
    if (this.videoElProps[assignedId] && this.videoElProps[assignedId].constraints) {
      this.videoElProps[assignedId].constraints = {
        video: {
          ...this.videoElProps[assignedId].constraints.video,
          ...ASPECT_RATIO[this.videoElProps[assignedId].resolution],
          advanced: [
            { aspectRatio: { exact: ratio } },
          ]
        }
      }
      this.videoElProps[assignedId].ratio = ratio
    }
    this.changeInputVideoProps(assignedId, this.videoElProps[assignedId].constraints);
  }
  changeResolution({ assignedId, resolution }) {
    if (this.videoElProps[assignedId] && this.videoElProps[assignedId].constraints) {
      this.videoElProps[assignedId].constraints = {
        video: {
          ...this.videoElProps[assignedId].constraints.video,
          ...ASPECT_RATIO[resolution],
          advanced: [{ aspectRatio: { exact: 1.667 } }]
        }
      }
      this.videoElProps[assignedId].resolution = resolution
    }
    this.changeInputVideoProps(assignedId, this.videoElProps[assignedId].constraints);
  }
  changeInputVideoProps(assignedId, constraints) {
    const streamOld = this.videoElProps[assignedId].videoEl.srcObject
    streamOld.getTracks()[0].applyConstraints(constraints.video)
      .then(() => {
        this.videoElProps[assignedId].srcObject = streamOld;
      });
  }

  pauseMediaProducer({ assignedId }) {
    this.mediaProducers.map(mp => {
      if (mp.assignedId === assignedId) {
        mp.producer.pause();
      }
      return ""
    })
  }
  resumeMediaProducer({ assignedId }) {
    this.mediaProducers.map(mp => {
      if (mp.assignedId === assignedId) {
        mp.producer.resume();
      }
      return "";
    })
  }
  createAudioProducer(constraints) {
    this.audioTransmitting = true;
    navigator.mediaDevices.getUserMedia(constraints)
      .then(async stream => {
        const assignedId = uuid();
        const producer = await this.producerTransport.produce({ track: stream.getAudioTracks()[0], appData: { assignedId } });
        // @ts-ignore
        this.audioStream = stream;
        this.mediaProducers.push({
          assignedId,
          producer
        });
        this.callback({
          type: "audiocreated",
          data: {
            stream,
            assignedId
          }
        })
      })
      .catch(e => {
        this.callback({
          type: "audioDevicePermissionIssue"
        })
      });
  }

  whiteNoise() {
    let canvas = Object.assign(document.createElement("canvas"), { width: 320, height: 240 });
    let ctx = canvas.getContext('2d');
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let p = ctx.getImageData(0, 0, canvas.width, canvas.height);
    requestAnimationFrame(function draw() {
      for (var i = 0; i < p.data.length; i++) {
        p.data[i++] = p.data[i++] = p.data[i++] = Math.random() * 255;
      }
      ctx.putImageData(p, 0, 0);
      requestAnimationFrame(draw);
    });
    return canvas.captureStream(60);
  }
  toggleBW(assignedId) {
    // if (this.filters[assignedId] === BLACK_AND_WHITE) {
    //   this.filters[assignedId] = null;
    // } else {
    //   this.filters[assignedId] = BLACK_AND_WHITE;
    // }
  }
  toggleBrightness(assignedId, val) {
    if (!this.filters[assignedId]) {
      this.filters[assignedId] = {};
    }
    let brightness = this.filters[assignedId].brightness || 1;
    if (!brightness) {
      brightness = 1;
    }
    brightness += val;

    if (brightness < 1) {
      brightness = 1;
    }
    if (brightness > 4) {
      brightness = 4;
    }
    this.filters[assignedId].brightness = brightness;
    this.callback({
      type: "updateFilter",
      data: {
        ...this.filters[assignedId],
        assignedId
      }
    })
    // @ts-ignore
    this.emit({
      type: "updateFilter",
      brightness: this.filters[assignedId].brightness,
      assignedId
    })
  }

  createCanvas(stream, assignedId) {
  }

  async createVideoProducer(constraints, stream) {
    constraints = {
      ...constraints,
      video: {
        ...constraints.video,
        // ...ASPECT_RATIO["1"],
        advanced: [
          { aspectRatio: { exact: 1.6667 } },
          { frameRate: { max: 24 } }
        ]
      }
    }

    if (!stream) {
      let errThrown = false;
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints)
      } catch (e) {
        errThrown = true;
        this.callback({
          type: "videoDevicePermissionIssue"
        })
      }
      if (errThrown) {
        return;
      }
    }

    if (!stream) {
      console.error("Permission denied");
      return;
    }

    let assignedId = uuid();
    if (this.webrtcType === "p2p") {
      assignedId = stream.id;
    }
    this.videoElProps[assignedId] = {
      type: "webcam",
      constraints: constraints,
      resolution: 2,
      aspectRatio: 1.5,
      videoEl: {
        srcObject: stream
      }
    };
    // @ts-ignore
    this.mainVideoEl = {
      srcObject: stream
    };
    if (this.webrtcType === "p2p") {
      this.p2pAdapter.addVideoStream(stream);
    } else {
      let options = {
        stopTracks: false,
        track: stream.getVideoTracks()[0],
        appData: { assignedId }
      }
      if (this.simulcast) {
        // @ts-ignore
        options.encodings = WEBCAM_SIMULCAST_ENCODINGS;
      }

      const producer = await this.producerTransport.produce(options);
      this.mediaProducers.push({
        assignedId,
        producer,
      });
      this.consolidateOutgoingVideoResolutions()
      if (this.mediaConsumers.find(x => x.isDesktop)) {
        // a user is sharing desktop
        // @ts-ignore
        this.downgradeOutgoingVideo(assignedId)
      }
    }
    this.callback({
      type: "videocreated",
      data: {
        stream,
        assignedId,
        metadata: this.metadata
      }
    })
  }
  getDisplayStream(constraints) {
    return navigator.mediaDevices.getDisplayMedia(constraints);
  }
  async createDesktopProducer(constraints, stream) {
    if (!stream) {
      stream = await this.getDisplayStream(constraints);
    }
    if (!stream) {
      console.error("Access denied while asking for screenshare");
      return;
    }
    let assignedId = uuid();
    if (this.webrtcType === "p2p") {
      assignedId = stream.id
      this.p2pAdapter.addVideoStream(stream, true);
    } else {
      const producer = await this.producerTransport.produce({
        stopTracks: false,
        track: stream.getVideoTracks()[0],
        appData: { assignedId, isDesktop: true }
      })
      this.mediaProducers.push({
        assignedId,
        producer,
        isDesktop: true
      });
    }
    this.videoElProps[assignedId] = {
      type: "desktop",
      constraints: constraints,
      videoEl: {
        srcObject: stream
      }
    };
    // @ts-ignore
    this.downgradeOutgoingVideos();
    this.callback({
      type: "desktopvideocreated",
      data: {
        stream,
        assignedId,
        isDesktop: true
      }
    })
    const self = this;
    stream.getVideoTracks()[0].onended = function () {
      self.destroyMediaProducer({ assignedId })
      self.callback({
        type: "desktopVideoDestroyed"
      })
    };
    ///conn.send(succes)
  }
  async destroyMedia(data) {
    const { assignedId } = data;
    // let mediaConsumer = this.mediaConsumers.find(x => x.assignedId === assignedId);
    // if (mediaConsumer?.isDesktop) {
    //   // this.normalizeOutgoingVideos();
    //   this.consolidateOutgoingVideoResolutions();
    // }
    this.mediaConsumers = this.mediaConsumers.filter(x => x.assignedId !== assignedId);
    this.consolidateOutgoingVideoResolutions();
    this.callback({
      type: "destroyMedia",
      data: {
        assignedId
      }
    })
  }
  async startRecording({ audioTrack, videoTrack }) {
    const assignedId = uuid();
    const videoProducer = await this.producerTransport.produce({
      track: videoTrack,
      appData: { assignedId, isRecording: true }
    })
    const audioProducer = await this.producerTransport.produce({
      track: audioTrack,
      appData: { assignedId, isRecording: true }
    })
  }
  async consumeMedia(data) {
    for (let i = 0; i < data.length; i++) {
      const { peerId, assignedId, consumerOptions, mediaId, isDesktop, audioMuted, isMuted, metadata, name, user_id } = data[i];
      if (!this.consumerTransport) {
        console.error("Consumer transport not yet initialized")
        return;
      }
      await this.consumerTransport.consume(consumerOptions)
        .then((newConsumer) => {
          this.mediaConsumers.push({
            assignedId,
            consumer: newConsumer,
            peerId,
            isDesktop
          })
          this.consolidateOutgoingVideoResolutions()
          if (isDesktop) {
            // @ts-ignore
            this.downgradeOutgoingVideos();
          }
          this.callback({
            type: "consumemedia", data: {
              track: newConsumer.track, peerId, assignedId, mediaId, isDesktop, audioMuted, metadata, name, user_id, isMuted
            }
          })
        })
    };
  }
  async getOutgoingBandwidth() {
    if (!this.producerTransport) {
      return {};
    }
    const stats = await this.producerTransport.getStats();
    let audioBytes = 0;
    let videoBytes = 0;
    stats.forEach(r => {
      if (r.type.includes("outbound-rtp")) {
        if (r.kind === "audio") {
          audioBytes += r.bytesSent;
        } else {
          videoBytes += r.bytesSent;
        }
      }
    })
    return {
      videoBytes,
      audioBytes
    }
  }
  async getIncomingBandwidth() {
    if (!this.consumerTransport) {
      return {};
    }
    const stats = await this.consumerTransport.getStats();
    let audioBytes = 0;
    let videoBytes = 0;
    stats.forEach(r => {
      if (r.type.includes("inbound-rtp")) {
        if (r.kind === "audio") {
          audioBytes += r.bytesReceived;
        } else {
          videoBytes += r.bytesReceived;
        }
      }
    })
    return {
      videoBytes,
      audioBytes
    }
  }
}
