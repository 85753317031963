import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { IndividualVideoCircular } from '../IndividualVideoCircular';
import { useState } from 'react';
import { useLayoutEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  roundOuter: {
    padding: 10
  }
}));

export const AroundVideos = ({ videoStreams, height = 200 }) => {
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 760;
  
  return (
    <div className={classes.root}>
      {videoStreams.map((vs, index) => <div key={index} className={classes.roundOuter}><IndividualVideoCircular
        height={isMobile ? 50 : 125}
        key={vs.assignedId}
        {...vs}
      />
      </div>
      )}
    </div>
  )
}
