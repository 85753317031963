import React, { useState, useEffect, useRef } from "react";
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import componentStyles from "./chat/chat-styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import { PeerList } from "./PeerList";

//@ts-ignore
const useStyles = makeStyles(componentStyles);
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))(Badge);

// @ts-ignore
export const AvatarGroupComponent = ({peers, type, isMobileLandscape}) => {
  //@ts-ignore
  const classes = useStyles();
  if (!peers || !peers.length) {
    //@ts-ignore
    return  <center>
      <Typography style={{fontSize: isMobileLandscape ? 13 : 20}}>
        You're the only one here!
      </Typography>
      {/*
       //@ts-ignore */}
    </center>
  }
  return (
      <div>
      <PeerList isMobileLandscape={isMobileLandscape} type={type} peers={peers} />
      </div>
  );
};
