import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { AroundVideos } from '../../videos/AroundVideos';
import { AroundScreenshare } from '../AroundScreenshare';
import { AroundControls } from './AroundControls';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "inline-block",
    padding: 10,
    borderRadius: 10,
    transition: "background .5s cubic-bezier(0.4,0.0,0.2,1)",
    //@ts-ignore
    background: ({ mouseOver }) => mouseOver ? "rgba(0, 0, 0, 0.8)" : ""
  },
  flex: {
    display: "flex"
  }
}));

export const AroundLayout = ({
  setWhiteboard,
  whiteboard,
  toggleScreenshare,
  videoMuted,
  localVideoStreams,
  setVideoMuted,
  videoStreams,
  videoAssignedId,
  setVideoStreams,
  setLocalAudioStreams,
  audioState,
  cameras,
  screenShareOn,
  mics,
  sinks,
  someoneIsSharing,
  type,
  setAudioState }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const classes = useStyles({ mouseOver });

  return (
    <
      // disabled={whiteboard}
    >
      <div
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        className={classes.root}
      >
        {!someoneIsSharing.peerId
        //  && !whiteboard  
         && <AroundVideos
                //@ts-ignore
          setVideoStreams={setVideoStreams}
          videoStreams={videoStreams.filter(x => !x.isDesktop)}
        />}
        {someoneIsSharing.peerId && <AroundScreenshare
          setVideoStreams={setVideoStreams}
          screenShareOn={screenShareOn}
          videoStreams={videoStreams}
          audioState={audioState}
        />}
        {/* {!someoneIsSharing.peerId && whiteboard && <Whiteboard 
          setVideoStreams={setVideoStreams}
          videoStreams={videoStreams}
          type={type}
        />} */}
        <div style={{ visibility: mouseOver ? "visible" : "hidden" }}>
          {/*
          //@ts-ignore */}
          <AroundControls
            type={type}
            setWhiteboard={setWhiteboard}
            whiteboard={whiteboard}
            screenShareOn={screenShareOn}
            toggleScreenshare={toggleScreenshare}
            mics={mics}
            setVideoStreams={setVideoStreams}
            videoMuted={videoMuted}
            localVideoStreams={localVideoStreams}
            setVideoMuted={setVideoMuted}
            videoAssignedId={videoAssignedId}
            setLocalAudioStreams={setLocalAudioStreams}
            audioState={audioState}
            setAudioState={setAudioState}
            cameras={cameras}
            sinks={sinks}
          />
        </div>
      </div>
    </>
  )
}
