import React from 'react'
import { Whiteboard } from '../../Whiteboard'

export const WhiteboardContainer = () => {
  return (
    <div style={{border: "1px solid black"}}>
        {/*
    // @ts-ignore */}
      <Whiteboard
        type={"producer"}
      />
    </div>
  )
}
