import { IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginBottom: -60,
    background: "#ecf0f1",
    height: 50,
    border: "0px",
    borderRadius: "2px",
    marginRight: theme.spacing(1),
    backgroundColor: "white"
  }
}))

export const IndividualScreenshareVideo = (props) => {
  const videoRef = useRef(null)
  const [marginTop, setMarginTop] = useState(56.25)
  const classes = useStyles();

  useEffect(() => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    videoRef.current.srcObject = props.stream;

    videoRef.current.play();
    function listener() {
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        setMarginTop(56.25 + 100 * (videoHeight - videoWidth * 1 / 1.77) / (2 * videoHeight))
      }
    }
    videoRef.current.addEventListener("resize", listener)
    return () => {
      if (videoRef.current)
        videoRef.current.removeEventListener("resize", listener)
    }
  }, [videoRef, props.stream])

  return (
    <video style={{ width: "100%", borderRadius: 10}} ref={videoRef} autoPlay={true} playsInline={true} />
  )
}
