import { Grid } from '@material-ui/core'
import React from 'react'
import { IndividualVideo } from './IndividualVideo'

export const Layout4 = (props) => {
  const { isMobile } = props.isMobile;
  const getPadding = () => {
    return isMobile ? "2vw" : "1vw"
  }

  const getPadding2 = () => {
    return isMobile ? "2vw" : "1vw"
  }

  if(props.layout === 0) {
    return <div style={{ paddingRight: getPadding() , paddingLeft: getPadding() }}>
    <Grid spacing={2} container>
      {props.videoStreams.map(vs =>
        <Grid key={vs.assignedId} item sm={12} md={6} lg={6}>
          <IndividualVideo {...props} {...vs} />
        </Grid>
      )}
    </Grid>
  </div> 
  }
  return (
     <div style={{ paddingRight: getPadding2(), paddingLeft: getPadding2() }}>
      <Grid container>
        {props.videoStreams.map(vs =>
          <Grid key={vs.assignedId} item sm={12} md={3} lg={3}>
            <IndividualVideo {...props} {...vs} />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
