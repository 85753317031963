import { Grid, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { AroundVideos } from '../videos/AroundVideos';
import { IndividualScreenshareVideo } from '../IndividualScreenshareVideo';

export const ScreenshareLayout = (props) => {
  let screenshareVideoStream = props.localVideoStreams.find(x => x.type === "desktop") || props.videoStreams.find(x => x.assignedId === props.someoneIsSharing.assignedId);
  const nonScreenSharedVideos =  props.videoStreams.filter(x => x.assignedId !== props.someoneIsSharing.assignedId);
  console.error(screenshareVideoStream);
  const {isMobile} = props.isMobile;

  const getStyles = () => {
    if(isMobile) {
      return {width: "217px", height: "130.5px"}
    }
    return {width: "290px", height: "174px"}
  }
  return (
    <div>
      {/* <div style={{display: "flex", justifyContent: "center", justifyItems: "center"}}>
          {nonScreenSharedVideos.map(videoStream => <div style={getStyles()}>
            <IndividualVideo {...props} key={videoStream.assignedId} {...videoStream} />  
          </div>)}
      </div> */}
      {screenshareVideoStream && screenshareVideoStream.assignedId && <Grid container>
        <Grid item sm={12} md={12} lg={12} style={{"position": "relative"}}>
          <IndividualScreenshareVideo {...props} key={screenshareVideoStream.assignedId} {...screenshareVideoStream} />
        </Grid>
      </Grid>}
    </div>
  )
}
