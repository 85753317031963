import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  btnStylesMU:
  {
    position: "relative",
    minHeight: "40px",
    //@ts-ignore
    padding: ({ size }) => size === "small" ? "7px 15px" : "8px 24px",
    "-webkit-box-pack": "center",
    "-webkit-font-smoothing": "antialiased",
    "-webkit-text-size-adjust": "100%",
    "-webkit-justify-content": "center",
    "-ms-flex-pack": "center",
    "justify-content": "center",
    "-webkit-box-align": "center",
    "-webkit-align-items": "center",
    "-ms-flex-align": "center",
    "align-items": "center",
    borderStyle: "solid",
    borderWidth: "2px",
    //@ts-ignore
    "border-color": ({ theme }) => theme.contrast,
    borderRadius: "500px",
    //@ts-ignore
    "background-color": ({ theme }) => theme.primary,
    "-webkit-transition": "all 300ms ease",
    "transition": "all 300ms ease",
    //@ts-ignore
    color: ({ theme }) => theme.contrast,
    "font-size": "14px",
    "line-height": "24px",
    "font-weight": 500,
    "text-align": "center",
    "letter-spacing": "-0.01em",
    "white-space": "nowrap",
    // display: "inline-block",
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;',
    //@ts-ignore
    backgroundColor: ({ theme }) => theme.primary,
    border: 0,
    lineHeight: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    // borderRadius: 0,
    '&:hover': {
      //@ts-ignore
      "border-color": ({ theme, disableHover }) => disableHover ? "" : theme.primary,
      //@ts-ignore
      "background-color": ({ theme, disableHover }) => disableHover ? "" : theme.contrast,
      //@ts-ignore
      color: ({ theme, disableHover }) => disableHover ? "" : theme.primary,
    },
    '&:active': {
      //@ts-ignore
      "border-color": ({ theme, disableHover }) => disableHover ? "" : theme.primary,
      //@ts-ignore
      "background-color": ({ theme, disableHover }) => disableHover ? "" : theme.contrast,
    },
    '&:focus': {
      //@ts-ignore
      border: ({ theme }) => `2px solid ${theme.contrast}`
    }
  },
  btnStylesDisabled: {
    cursor: "",
    position: "relative",
    minHeight: "40px",
    //@ts-ignore
    padding: ({ size }) => size === "small" ? "7px 15px" : "8px 24px",
    "-webkit-box-pack": "center",
    "-webkit-font-smoothing": "antialiased",
    "-webkit-text-size-adjust": "100%",
    "-webkit-justify-content": "center",
    "-ms-flex-pack": "center",
    "justify-content": "center",
    "-webkit-box-align": "center",
    "-webkit-align-items": "center",
    "-ms-flex-align": "center",
    "align-items": "center",
    borderStyle: "solid",
    borderWidth: "2px",
    "border-color": "#999999",
    borderRadius: "500px",
    "background-color": "#cccccc",
    "-webkit-transition": "all 300ms ease",
    "transition": "all 300ms ease",
    color: "#999999",
    "font-size": "14px",
    "line-height": "24px",
    "font-weight": 500,
    "text-align": "center",
    "letter-spacing": "-0.01em",
    "white-space": "nowrap",
    // display: "inline-block",
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;',
    //@ts-ignore
    backgroundColor: ({ theme }) => theme.primary,
    border: 0,
    lineHeight: "inherit",
    textDecoration: "none",
  }
}))
export const Button = ({ theme, onClick, title, disableHover, size, disabled }) => {
  const classes = useStyles({ theme, disableHover, size, });
  if (disabled) {
    return <a className={classes.btnStylesDisabled} >{title}</a>
  }
  return (
    <a className={classes.btnStylesMU} onClick={onClick}>{title}</a>
  )
}
