import { IconButton, makeStyles } from '@material-ui/core';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  btnSmall: {
    background: "black",
    width: 14,
    height: 14,
    padding: 10,
    "border-top-left-radius": 5,
    "border-bottom-left-radius": 5,
    "border-top-right-radius": 5,
    "border-bottom-right-radius": 5,
    // @ts-ignore
    padding: 14
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
}));

export const ShareScreenAround = ({ toggleScreenshare, screenShareOn }) => {
  const classes = useStyles();

  return (
    <>
      <IconButton onClick={toggleScreenshare} className={classes.btnSmall} size="small">
        {screenShareOn ? <StopScreenShareIcon className={classes.btnAroundInner} /> : <ScreenShareIcon className={classes.btnAroundInner} />}
      </IconButton>
    </>
  )
}
