import { IconButton, makeStyles } from '@material-ui/core';
import { AROUND } from "../../../constants";
import React from 'react';
import CreateIcon from '@material-ui/icons/Create';
const useStyles = makeStyles((theme) => ({
  icon: {
    // @ts-ignore
    color: ({theme}) => theme === "dark" ? "white" : "#192a56",
    fontSize: 25, 
    marginRight: 5
  },
  btnSmall: {
    background: "black",
    width: 14,
    height: 14,
    padding: 10,
    "border-top-left-radius": 5,
    "border-bottom-left-radius": 5,
    "border-top-right-radius": 5,
    "border-bottom-right-radius": 5,
    // @ts-ignore
    padding: 14
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
  btn: {
    background: "#ecf0f1",
    width: 65,
    height: 50,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#dfe4ea"
    },
    fontSize: 20
  }
}));

export const WhiteboardControls = ({ theme, whiteboard, setWhiteboard, view }) => {
  const classes = useStyles({theme});

  const toggle = () => {
    setWhiteboard(x => {
      window.adapter.setWhiteboardState(!x);
      return !x
    });
  }

  if (view === AROUND) {
    return (
      <>
        <IconButton onClick={toggle} className={classes.btnSmall} size="small">
          <CreateIcon  className={classes.btnAroundInner}/>
        </IconButton>
      </>
    )
  }

  return (
    <IconButton className={classes.btn} style={{border: "1px solid #ced6e0", background: "#f1f2f6", borderRadius: 7}} onClick={toggle} size="small">
      <div>
      <div style={{marginBottom: 0}}><CreateIcon style={{fontSize: 20, color: whiteboard ? "#27ae60" : ""}} /></div>
        <div style={{fontSize: 11, marginBottom: -5,  color: "black" }}>
          Whiteboard
        </div>
      </div>
    </IconButton>
  )
}
