import { CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Layout1 } from './layouts/Layout1'
import { Layout2 } from './layouts/Layout2'
import { Layout3 } from './layouts/Layout3'
import { Layout4 } from './layouts/Layout4'
import { Layout5 } from './layouts/Layout5'
import { Layout6 } from './layouts/Layout6'
import { Layout7 } from './layouts/Layout7'
import { Layout8 } from './layouts/Layoutt8'
import { PinnedLayout } from './layouts/PinnedLayout'
import { ScreenshareLayout } from './layouts/ScreenshareLayout'
import { SoloLayout } from './layouts/SoloLayout'
import { Whiteboard } from './Whiteboard'
import { WhiteboardFullscreenLayout } from './WhiteboardFullscreenLayout'
import { PdfLayout } from './PdfLayout'

export const Videos = (props) => {
  const loaded = props.loaded;
  const setLoaded = props.setLoaded;

  useEffect(() => {
    if (props.videoStreams.length > 0) {
      setLoaded(true);
    }
  }, [props.videoStreams]);

  const isMobile = props.isMobile;
  const isMid = props.isMid;
  if (props.someoneIsSharing?.assignedId || props.screenShareOn) {
    return <ScreenshareLayout isMobile={isMobile} {...props} />
  }

  if (props.pdfSlide && props.pdfData?.route) {
    return <PdfLayout isMobile={isMobile} {...props} />
  }

  if (props.whiteboard) {
    return <WhiteboardFullscreenLayout
      isMobile={isMobile} {...props} 
    />
  }

  if (props.solo) {
    return <SoloLayout isMobile={isMobile} {...props} />
  }

  // if (props.pinned) {
  //   return <PinnedLayout {...props} />
  // }

  if (props.videoStreams.length == 1) {
    return <Layout1
      isMobile={isMobile}
      {...props}
    />
  }

  if (props.layout === 2) {
    return <PinnedLayout isMobile={isMobile} {...props} />
  }
  if (props.videoStreams.length === 2) {
    return <Layout2
      isMobile={isMobile}
      {...props}
    />
  }
  if (props.videoStreams.length === 3) {
    return <Layout3
      isMobile={isMobile}
      {...props}
    />
  }
  if (props.videoStreams.length === 4) {
    return <Layout4
      isMobile={isMobile}
      {...props}
    />
  }
  if (props.videoStreams.length === 5) {
    return <Layout5
      isMid={isMid}
      isMobile={isMobile}
      {...props}
    />
  }

  if (props.videoStreams.length === 6) {
    return <Layout6
      isMobile={isMobile}
      {...props}
    />
  }

  if (props.videoStreams.length === 7) {
    return <Layout7
      isMobile={isMobile}
      {...props}
    />
  }
  if (props.videoStreams.length >= 8) {
    return <Layout8
      isMobile={isMobile}
      {...props}
    />
  }

  return <div style={{}}>{/*
    // @ts-ignore */}
    {!loaded && <center><CircularProgress size={25} color="primary" /> </center>}
    <Typography variant="h6">{/*
    // @ts-ignore */}
      <center>
      Please wait while people to join in.{/*
    // @ts-ignore */}
      </center>
    </Typography>
  </div>
}
