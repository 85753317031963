import React from 'react'
import { makeStyles } from '@material-ui/core';
import { WhiteboardContainer } from './WhiteboardContainer';
import { VideoChatContainer } from './VideoChatContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  whiteboardContainer: {
    flexGrow: 1,
  },
  videoChatContainer: {
    
  }
}))

export const UnacademyLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.whiteboardContainer}>
        <WhiteboardContainer />
      </div>
      <div className={classes.videoChatContainer}>
        <VideoChatContainer />
      </div>
    </div>
  )
}
