import React, { useState, useEffect, useRef } from "react";
import { IndividualVideo } from '../layouts/IndividualVideo'
import { Poll } from '../Poll';
import axios from "axios";
import PersonIcon from '@material-ui/icons/Person';
import ChatIcon from '@material-ui/icons/Chat';
import Chip from '@material-ui/core/Chip';
import clsx from "clsx";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FaceIcon from '@material-ui/icons/Face';
import Tab from '@material-ui/core/Tab';
// @material-ui/core components
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { Button } from "../Button2";
import Card from "@material-ui/core/Card";
import SendIcon from '@material-ui/icons/Send';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import ClearIcon from '@material-ui/icons/Clear';
import CallEndIcon from '@material-ui/icons/CallEnd';
// @material-ui/lab components
import AvatarGroup from "@material-ui/lab/AvatarGroup";
// @material-ui/icons components
import Add from "@material-ui/icons/Add";
import ModeComment from "@material-ui/icons/ModeComment";
import Share from "@material-ui/icons/Share";
import ThumbUp from "@material-ui/icons/ThumbUp";
// core components
import componentStyles from "./chat-styles";
import { FormControl, InputLabel, Select, MenuItem, Tooltip, AppBar, Tabs, Paper } from "@material-ui/core";
import { AvatarGroupComponent } from "../AvatarGroupComponent";
const emojis = ["👍", "👎", "👏"]
//@ts-ignore
const useStyles = makeStyles(componentStyles);
let mouseInterval;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))(Badge);

let emojiCount = [0, 0, 0];
export default function Chat({
  pollData,
  type,
  room,
  setChat,
  isMobileLandscape,
  uaLayout,
  accessKey,
  token,
  videoStreams,
  destroyEndpoint,
  setLeft, messages = [], setMessages, name, peers, quality, setQuality }) {
  const classes = useStyles({ isMobileLandscape });
  const [message, setMessage] = useState("");
  const messagesEnd = useRef();
  const emoji1 = useRef();
  const emoji2 = useRef();
  const emoji3 = useRef();
  const [emojiSet, setEmojiSet] = useState({});
  const [handRaised, setHandRaised] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (handRaised) {
      window.setTimeout(() => {
        setHandRaised(false);
      }, 5000)
    }
  }, [handRaised]);

  const addEmoji = (index) => {
    if (mouseInterval) {
      window.clearTimeout(mouseInterval);
    }
    let ref = null;
    if (index === 0) {
      ref = emoji1;
    }
    if (index === 1) {
      ref = emoji2;
    }
    if (index === 2) {
      ref = emoji3;
    }
    if (ref && ref.current) {
      ref.current.children[0].innerHTML = `${emojis[index]} ${++emojiCount[index]}`
    }

    mouseInterval = window.setTimeout(() => {
      emojiCount = [0, 0, 0];
      if (emoji1.current) {
        // @ts-ignore
        emoji1.current.children[0].innerHTML = `${emojis[0]} ${0}`
      }
      if (emoji2.current) {
        // @ts-ignore
        emoji2.current.children[0].innerHTML = `${emojis[1]} ${0}`
      }
      if (emoji3.current) {
        // @ts-ignore
        emoji3.current.children[0].innerHTML = `${emojis[2]} ${0}`
      }
    }, 7000);
  }

  useEffect(() => {
    window.setTimeout(() => {
      if (window.adapter) {
        window.adapter.registerEmojiCallback((data) => {
          addEmoji(data.index)
        })
      }
    }, 100)
  }, [])
  const _getMessage = ({ type, message, name }) => {
    if (type === "peerAdded") {
      // @ts-ignore
      return <center><Box
        borderRadius=".4375rem"
        position="relative"
        className={classes.peerJoinedCard}
      >
        <Typography style={{ fontSize: isMobileLandscape ? 8 : 14, color: "rgb(134, 152, 166)" }}>
          {name} joined
      </Typography>
        <Box
          component="p"
          marginTop="0.4rem"
          marginBottom="0.1rem"
          fontWeight="300"
          lineHeight="1"
          fontSize="1rem"
        >
          {message}
        </Box>
      </Box>
        {/*
        //@ts-ignore */}
      </center>
    }
    return <Box
      marginTop={isMobileLandscape ? "0.2rem" : "1rem"}
      display="flex"
      alignItems="flex-start"
      className={classes.mediaRoot}
    >
      {!isMobileLandscape && <Avatar
        classes={{ root: isMobileLandscape ? classes.avatarRootMediaSmall : classes.avatarRootMedia }}
        alt="..."
        src={`https://ui-avatars.com/api/?name=${name}&background=2c3e50&color=ecf0f1&rounded=true`}
      />}
      <Box display="flex" alignItems="flex-start">
        <Box
          borderRadius=".4375rem"
          position="relative"
          className={isMobileLandscape ? classes.mediaCommentSmall : classes.mediaComment}
          padding={isMobileLandscape ? "0px" : "0.2rem 1.25rem 0.5rem 2.5rem"}
          style={{ paddingLeft: isMobileLandscape ? "5px" : "2.5rem" }}
        >{/*
    // @ts-ignore */}
          {!isMobileLandscape && <Typography variant="subtitle2" component="subtitle1" style={{ color: "rgb(134, 152, 166)" }} style={{ fontSize: isMobileLandscape ? 7 : 13 }}>
            {name}
          </Typography>}
          <Box
            component="p"
            marginTop={isMobileLandscape ? "0.1rem" : "0.4rem"}
            marginBottom="0.1rem"
            fontWeight="300"
            lineHeight="1"
            fontSize={isMobileLandscape ? "0.7rem" : "1rem"}
              // @ts-ignore
            style={{ "overflow-wrap": "break-word" }}
          >
            <b>{isMobileLandscape && name + ": "}</b>{message}
          </Box>
        </Box>
      </Box>
    </Box>
  }

  useEffect(() => {
    if (messagesEnd.current) {
      //@ts-ignore
      messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight;
    }
  }, [messages])
  const keyPress = (e) => {
    if (e.keyCode == 13) {
      _sendMessage();
    }
  }
  const _sendMessage = () => {
    if (!message || message.trim().length == 0) {
      return;
    }
    setMessage("");
    // @ts-ignore
    window.adapter.emit({
      type: "sendMessage",
      message
    })
    setMessages(x => [...x, {
      message,
      // @ts-ignore
      name: window.adapter.name
    }])
  }
  const getEmojiRef = (index) => {
    if (index === 0)
      return emoji1
    if (index === 1) {
      return emoji2
    }
    return emoji3
  }
  const getMobileScale = (type) => {
    if (type === 0) {
      return isMobileLandscape ? 12 : 20;
    }
    if (type === 1) {
      return isMobileLandscape ? 20 : 34;
    }
  }
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardContent style={{ height: "99vh", display: "flex", flexDirection: "column", padding: isMobileLandscape ? 4 : 16, paddingTop: 2 }}>
          <div>
            {/*
            // @ts-ignore */}
            <Grid
              container
              component={Box}
              alignItems="center"
              paddingBottom={isMobileLandscape ? "0rem" : "1rem"}
              marginTop={isMobileLandscape ? "0rem" : "1rem"}
              marginBottom={isMobileLandscape ? "0.5rem" : "1rem"}
              borderBottom={"1px solid gray"}
            >
              <div style={{ "display": "flex", width: "100%" }}>
                <div style={{ display: "flex", flexGrow: 1, maxHeight: isMobileLandscape ? 15 : 25 }}>
                  {((uaLayout && type === "producer") || !uaLayout) && !isMobileLandscape && <> <span style={{ padding: 4, fontSize: 12, borderRadius: 2, border: "1px solid gray", minWidth: 10, display: "flex", justifyContent: "center", marginRight: 4 }}>
                    <PersonIcon style={{ fontSize: 14, marginRight: 2 }} />
                    <span>
                      {peers.length + 1}
                    </span>
                  </span></>}
                  <span style={{ marginRight: 20, padding: isMobileLandscape ? 3 : 5, backgroundColor: "rgb(235, 87, 87)", fontSize: isMobileLandscape ? 8 : 12, borderRadius: 2, color: "white" }}>
                    Live
                  </span>
                </div>
                {/* {!uaLayout && <div style={{ marginRight: isMobileLandscape ? 2 : 10, marginTop: 1 }}>
                  <Select
                    style={{ maxHeight: isMobileLandscape ? 15 : 25 }}
                    value={quality}
                    onChange={(e) => {
                      setQuality(e.target.value)
                    }}
                  >
                    <MenuItem value={0}>Auto</MenuItem>
                    <MenuItem value={1}>720p</MenuItem>
                    <MenuItem value={2}>360p</MenuItem>
                  </Select>
                </div>} */}
                <div style={{ marginTop: 4 }}>
                  {/*
    // @ts-ignore */}
                  <Button disableHover={true} size="small" theme={{ primary: "rgb(235, 87, 87)", contrast: "white" }} title={<>Leave</>} onClick={() => {
                    setLeft(true)
                    window.adapter.stopMediaItems()
                  }}>
                  </Button>{" "}{" "}
                  {/*
    // @ts-ignore */}
                  <IconButton style={{ marginRight: 5, height: 22, width: 22 }} disableHover={true} size="small" onClick={() => {
                    setChat(false)
                  }}>{/*
    // @ts-ignore */}
                    <FullscreenIcon style={{ height: 22 }} size="small" />
                  </IconButton>
                </div>
              </div>
            </Grid>
          </div>
          {/*
    // @ts-ignore */}
          {uaLayout && type === "producer" && destroyEndpoint && <div style={{ display: "flex", "flex-direction": "row-reverse" }}> <Button style={{ width: 100, maxHeight: 30 }} disableHover={true} size="small" theme={{ primary: "rgb(235, 87, 87)", contrast: "white" }} title={"End Class"} onClick={() => {
            setLeft(true)
            try {
              window.adapter.stopMediaItems()
            } catch(e) {
              console.log(e);
            }
            if (destroyEndpoint) {
              try {
                axios.post(destroyEndpoint + "?room=" + room);
                axios.get(destroyEndpoint + "?room=" + room);
              } catch (e) {
                console.error(e);
              }
            }
          }}>
          </Button></div>}
          {uaLayout && videoStreams?.[0] &&
            <div style={{ marginTop: 2 }}>
              <IndividualVideo small={true} key={videoStreams[0].assignedId} {...videoStreams[0]} />
            </div>
          }
          {pollData.poll_id && <div style={{ marginTop: 1, }}>
            <> <Poll
              isMobileLandscape={isMobileLandscape}
              pollData={pollData}
              accessKey={accessKey}
              token={token}
            /><br /></>
          </div>}
          {!uaLayout && <Paper square style={{ width: "100%" }}>
            <Tabs
              style={{ minHeight: getMobileScale(1), height: getMobileScale(1) }}
              variant="fullWidth"
              value={currentTab}
              onChange={(e, newValue) => { setCurrentTab(newValue) }}
              //@ts-ignore
              variant="scrollable"
              scrollButtons="off"
              aria-label="scrollable prevent tabs example"
            >
              <Tab style={{ width: "50%", height: getMobileScale(1), minHeight: getMobileScale(1) }} icon={<ChatIcon style={{ height: getMobileScale(0), minHeight: getMobileScale(0) }} />} aria-label="chat" />
              <Tab style={{ width: "50%", height: getMobileScale(1), minHeight: getMobileScale(1) }} icon={<PersonIcon style={{ height: getMobileScale(0), minHeight: getMobileScale(0) }} />} aria-label="People" />
            </Tabs>
          </Paper>}
          <br />
          {currentTab === 0 && <> <div ref={messagesEnd} style={{
            flex: "1 1 auto",
            overflowY: "scroll",
            // maxHeight: `calc(100vh - ${!peers || peers.length === 0 ? 250 : 280}px)`
          }}>
            <div>
              {/*
    // @ts-ignore */}
              {messages.map((message, index) => <div key={index}>{_getMessage(message, index)}</div>)}
            </div>
          </div>
            <div>
              {/*
    // @ts-ignore */}
              <center>
                {emojis.map((emoji, index) => <Chip
                  key={index}
                  className={classes.chip}
                  ref={getEmojiRef(index)}
                  clickable
                  variant="outlined"
                  style={{ marginTop: 5, height: isMobileLandscape ? "20px" : "", marginRight: 3, width: isMobileLandscape ? "28px" : "", fontSize: isMobileLandscape ? "9px" : "" }}
                  label={`${emoji} ${emojiCount[index]}`}
                  color="primary"
                  onClick={() => {
                    if (emojiSet[index]) {
                      return;
                    }
                    setEmojiSet(x => {
                      return {
                        ...x, [index]: true
                      }
                    })
                    window.setTimeout(() => {
                      setEmojiSet(x => {
                        return {
                          ...x, [index]: false
                        }
                      })
                    }, 4000)
                    addEmoji(index)
                    // @ts-ignore
                    window.adapter.emit({
                      type: "emj",
                      index
                    })
                  }}
                />)}
                {!uaLayout && type !== "producer" && <Tooltip title={"Raise hand"} placement="top">
                  <Chip
                    className={classes.chip}
                    label={`🖐`}
                    style={{ marginLeft: isMobileLandscape ? 3 : 10, marginRight: isMobileLandscape ? 3 : 10, marginTop: 5, background: handRaised ? "red" : "", paddingLeft: 0, paddingRight: 0, height: isMobileLandscape ? "20px" : "", width: isMobileLandscape ? "28px" : "", fontSize: isMobileLandscape ? "9px" : "" }}
                    clickable
                    onClick={() => {
                      setHandRaised(true);
                      // @ts-ignore
                      window.adapter.raiseHand(true);
                    }}
                    color="secondary"
                    variant="outlined"
                  />
                </Tooltip>}
                {/*
    // @ts-ignore */}
              </center>
            </div>
            <div>
              <Box
                marginTop={isMobileLandscape ? "0.3rem" : "1rem"}
                marginBottom={isMobileLandscape ? "0.2rem!important" : "1rem!important"}
                display="flex"
                alignItems="center"
                className={classes.mediaRoot}
              >
                {!isMobileLandscape && <Avatar
                  classes={{ root: classes.avatarRootMediaComment }}
                  alt="..."
                  src={`https://ui-avatars.com/api/?name=${name}&background=2c3e50&color=ecf0f1&rounded=true`}
                  style={{ marginRight: 10 }}
                />}
                <Box flex="1 1">
                  <Box component={FormGroup} marginBottom="0!important">
                    <OutlinedInput
                      style={{ padding: isMobileLandscape ? "8px 4px" : "18.5px 14px", fontSize: isMobileLandscape ? 8 : "" }}
                      onKeyDown={keyPress}
                      fullWidth
                      value={message}
                      multiline
                      onChange={(e) => setMessage(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            style={{ width: isMobileLandscape ? 16 : "", height: isMobileLandscape ? 16 : "" }}
                            aria-label="Send"
                            onClick={_sendMessage}
                          >
                            <SendIcon style={{ fontSize: isMobileLandscape ? "13px" : "" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      rows="1"
                      placeholder="Write your message"
                    />
                  </Box>
                </Box>
              </Box>
            </div></>}
          {currentTab === 1 && <>
            <div ref={messagesEnd} style={{
              flex: "1 1 auto",
              overflow: "scroll",
              // maxHeight: `calc(100vh - ${!peers || peers.length === 0 ? 250 : 280}px)`
            }}>
              <AvatarGroupComponent isMobileLandscape={isMobileLandscape} type={type} peers={peers} />
            </div>
          </>}
        </CardContent>
      </Card>
    </>
  );
}
