import React from 'react';
import { IndividualVideo } from './IndividualVideo';

export const PinnedLayout = (props) => {
  if (props.videoStreams.length === 0) {
    return <></>
  }

  let pinnedVideoIndex = props.videoStreams.findIndex((videoStream) => videoStream.assignedId === props.pinned);
  //if(pinnedVideoIndex === -1) {
    pinnedVideoIndex = 0;
  //}
  const pinnedVideo = props.videoStreams[pinnedVideoIndex]
  const unpinnedVideos = props.videoStreams.filter((x, index) => index !== pinnedVideoIndex);
  let pinnedWidth = 100 * (props.videoStreams.length - 1) / props.videoStreams.length;
  let unpinnedWidth = 100 / (props.videoStreams.length);

  if(props.videoStreams.length === 6 || props.videoStreams.length === 7) {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: `${60}%` }}>
          <IndividualVideo {...props} {...pinnedVideo} />
        </div>
        <div style={{"width": "40%"}}>
          <div style={{ display: "flex" }}>
            <IndividualVideo {...props} {...unpinnedVideos[0]} />
            <IndividualVideo {...props} {...unpinnedVideos[1]} />
          </div>
          <div style={{ display: "flex" }}>
            <IndividualVideo {...props} {...unpinnedVideos[2]} />
            <IndividualVideo {...props} {...unpinnedVideos[3]} />
          </div>
          {props.videoStreams.length === 6 && <div style={{ }}>
            {/*
            @ts-ignore*/}
            <center>
              <div style={{"width": "50%"}}>
                <IndividualVideo {...props} {...unpinnedVideos[4]} />
              </div>
              {/*
            @ts-ignore*/}
            </center>
          </div>}
          {props.videoStreams.length === 7 &&  <div style={{ display: "flex" }}>
            <IndividualVideo {...props} {...unpinnedVideos[4]} />
            <IndividualVideo {...props} {...unpinnedVideos[5]} />
          </div>}
        </div>
      </div>  
    )
  }
  if(props.videoStreams.length >= 8) {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: `${50}%` }}>
          <IndividualVideo {...props} {...pinnedVideo} />
        </div>
        <div style={{"width": "50%"}}>
          <div style={{ display: "flex" }}>
            <IndividualVideo {...props} {...unpinnedVideos[0]} />
            <IndividualVideo {...props} {...unpinnedVideos[1]} />
            <IndividualVideo {...props} {...unpinnedVideos[2]} />
          </div>
          <div style={{ display: "flex" }}>
            <IndividualVideo {...props} {...unpinnedVideos[3]} />
            <IndividualVideo {...props} {...unpinnedVideos[4]} />
            <IndividualVideo {...props} {...unpinnedVideos[5]} />
          </div>
          <div style={{ }}>
            {/*
            @ts-ignore*/}
            <center>
              <div style={{"width": "33%"}}>
                <IndividualVideo {...props} {...unpinnedVideos[6]} />
              </div>
              {/*
            @ts-ignore*/}
            </center>
          </div>
        </div>
      </div>  
    )
  }

  if (props.videoStreams.length === 1) {
    pinnedWidth = 100;
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ width: `${pinnedWidth}%` }}>
        <IndividualVideo {...props} {...pinnedVideo} />
      </div>
      <div style={{ width: `${unpinnedWidth}%` }}>
        {unpinnedVideos.map(unpinnedVideo => <IndividualVideo {...props} {...unpinnedVideo} />)}
      </div>
    </div>
  )
}
