import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import OutsideAlerter from '../OutsideAlerter';
import { IconButton, makeStyles } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import React from 'react';
import { AROUND } from "../../constants";


//@ts-ignore
const useStyles = makeStyles((theme) => ({
  img: {
    width: 25
  },
  dropdown: {
    position: "relative",
    display: "inline-block",
    '&:hover': {
      background: "#ced6e0"
    }
  },  
  listItem: {
    paddingLeft: 5,paddingRight: 5, margin: 0, fontSize: 10
  },
  listRoot: {
    padding: 0,margin: 0
  },
  dropdownContent:  {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  btnSmall: {
    background: "black",
    width: "1em",
    height: "1em",
    padding: 10,
    "border-top-left-radius": 10,
    "border-bottom-left-radius": 10,
    "border-top-right-radius": 0,
    "border-bottom-right-radius": 0,
    //@ts-ignore
    padding: 14
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
  btn: {
    background: "#ecf0f1",
    width: 65,
    height: 50,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#dfe4ea"
    }
  }
}))

export const MuteIcon = ({ assignedId, setAudioState, peerId, audioState, setLocalAudioStreams, view, mics }) => {
  const classes = useStyles();
  const [showDevices, setShowDevices] = React.useState(false);
  const toggleMuteIcon = (peerId, newAudioMuted) => {
    setAudioState(audioState => ({
      ...audioState,
      [peerId]: {
        ...audioState[peerId],
        audioMuted: newAudioMuted
      }
    }))
  }

  const handleMicChange = (deviceId) => {
    let constraints = { deviceId: { exact: deviceId } };
    if (!deviceId || deviceId === "") {
      //@ts-ignore
      constraints = true;
    }
    navigator.mediaDevices.getUserMedia({ audio: constraints })
      .then(stream => {
        const track = stream.getAudioTracks()[0];
        //@ts-ignore
        window.adapter.replaceTrack({ track, assignedId, isAudio: true });
      })
  }

  const toggleMute = (peerId, newAudioMuted) => {
    setLocalAudioStreams(localAudioStreams => {
      if (peerId === "local") {
        if (!localAudioStreams[0]) {
          if (window.adapter.audioTransmitting) {
            console.warn("Creating audio element!")
            return localAudioStreams;
          }
          if (!newAudioMuted) {
            window.adapter.createAudioProducer({ audio: true });
            window.adapter.muteToggled(false);
            toggleMuteIcon(peerId, newAudioMuted);
          } else {
            console.log("local audio stream not found, have you provided access?");
          }
          return localAudioStreams;
        }
        if (newAudioMuted) {
          window.adapter.pauseMediaProducer({
            assignedId: localAudioStreams[0].assignedId,
          })
          window.adapter.muteToggled(newAudioMuted);
        } else {
          window.adapter.resumeMediaProducer({ assignedId: localAudioStreams[0].assignedId });
          window.adapter.muteToggled(newAudioMuted);
        }
        toggleMuteIcon(peerId, newAudioMuted);
      } else {
        // user is a remote peer
        window.adapter.muteUser(peerId);
      }
      return localAudioStreams;
    })
  }

  const toggleMuteParent = () => {
    if (peerId === "local") {
      toggleMute(peerId, !audioState["local"]?.audioMuted);
    } else {
      window.adapter.muteUser(peerId);
    }
  }

  if (view === AROUND) {
    return (
      <>
        {/*
        //@ts-ignore */}
        <IconButton onClick={toggleMuteParent} className={classes.btnSmall} size="small">
          {/*
        //@ts-ignore */}
          {audioState[peerId]?.audioMuted ? <MicOffIcon className={classes.btnAroundInner} /> : <MicIcon className={classes.btnAroundInner} />}
        </IconButton>
      </>
    )
  }

  const audioMuted = audioState[peerId]?.audioMuted;
  return (
    <>
    <span style={{position: "relative"}}>
        {/*
        //@ts-ignore */}
      <div className={classes.dropdownContent} style={{width: 220, visibility: showDevices ? "" : "hidden"}}>
        {/*
        //@ts-ignore */}
        <List component="nav" className={classes.listRoot} aria-label="contacts">
        {/*
        //@ts-ignore */}
          {mics.map((x, index) => <div key={index} ><ListItem onClick={() => handleMicChange(x.deviceId)} className={classes.listItem} button>
            <ListItemText primary={x.label ? x.label.substr(0, 22) + (x.label.length > 21 ? "..." : "") : `Cam ${index + 1}`} />
          </ListItem></div>)}
        </List>
      </div>
      {/*
        //@ts-ignore */}
      <IconButton style={{border: "1px solid #ced6e0", background: audioMuted ? "#e94435" : "#f1f2f6", borderRadius: 7}} onClick={toggleMuteParent} className={classes.btn} size="small">
        <div>
        <div style={{marginBottom: -4}}>{audioMuted ? <MicOffIcon style={{color: "white"}} /> : <MicIcon />}</div>
          <div style={{fontSize: 11, marginBottom: -5, color: audioMuted ? "white" : "black" }}>
            {audioMuted ? "Start audio" : "Stop audio"}
          </div>
        </div>
        {/*
        //@ts-ignore */}
        <OutsideAlerter onClick={() => {
          setTimeout(() => setShowDevices(false), 150)
        }}>
          <KeyboardArrowUpIcon onClick={(e) => {
            setShowDevices(true)
            e.stopPropagation();
            {/*
        //@ts-ignore */}
          }} className={classes.dropdown} style={{color: audioMuted ? "white" : "", position: "absolute", "top": "0", "right": "0", borderRadius: 3, width: 20, height: 20 }} >
          </KeyboardArrowUpIcon>
        </OutsideAlerter>
      </IconButton>
    </span>
    </>
  )
}
