import { Grid } from '@material-ui/core'
import React from 'react'
import { IndividualVideo } from './IndividualVideo'

export const Layout1 = (props) => {
  return (
    <div>
      <Grid container>
      <Grid item lg={1}></Grid>
      <Grid item sm={12} md={12} lg={10}>
          <IndividualVideo {...props} key={props.videoStreams[0].assignedId} {...props.videoStreams[0]} />
        </Grid>
      </Grid>
    </div>
  )
}
