import React from "react";
import { Stream } from "components";
import { Button, TextField, Typography } from '@material-ui/core'

const EndScreen = () => {
  return (
      // @ts-ignore
      <center>
        <div style={{
          height: "100vh",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignSelf: "center"
        }}>
          <div className="vertical-center">
            <div>
              <Typography variant="h5">
                Liked the demo?
              </Typography>
            </div>
            <br/>
            <Button size="small" href={"https://getstream.in"} color="primary" variant="contained">Create your own</Button>
          </div>
        </div>
        {/*
      @ts-ignore*/}
      </center>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  const [ended, setEnded] = React.useState(false);
  const [name, setName] = React.useState("");
  let consumer = new URLSearchParams(window.location.search).get("consumer")
  let room = new  URLSearchParams(window.location.search).get("room");
  let doEnabled = new  URLSearchParams(window.location.search).get("do");
  let norecord = new  URLSearchParams(window.location.search).get("norecord");
  let presentToken = new URLSearchParams(window.location.search).get("token");
  const accessKey = new URLSearchParams(window.location.search).get("accessKey") || "6Ope8Cve";
  const noInteract = new  URLSearchParams(window.location.search).get("noInteract") || false;
  let PRODUCER_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiSGFya2lyYXQiLCJ0eXBlIjoicHJvZHVjZXIiLCJyZWNvcmQiOmZhbHNlLCJyb29tIjoia2lyYXQtdGVzdCIsImlhdCI6MTYzNjMyMDI5MX0.t69m1Sx4pj4aQlG-vICmMqzEuDS9FUVkvSemZMoFJY4";
  const PRODUCER_TOKEN_NO_RECORD = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiVXNlciIsInJvb20iOiJhdWd1c3Qtcm9vbSIsInR5cGUiOiJwcm9kdWNlciIsInJlY29yZCI6ZmFsc2UsImlhdCI6MTYyNzg5Mjk5MX0.4tXK-_vkGpD3Xh0nAm39KMmthEVgCf2FMXimJZsrSBE";
  let CONSUMER_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiSGFya2lyYXQiLCJ0eXBlIjoiY29uc3VtZXIiLCJyZWNvcmQiOmZhbHNlLCJyb29tIjoia2lyYXQtdGVzdCIsImlhdCI6MTYzNjMyMDIxMH0.9mA5OARiEv1Tsg9gJ-7tTLu0NmoNkYfSY9zIp99y_QY"

  const destroyEndPoint = "https://tempapi.appx.co.in/post/webrtc_class_logs";
  const token = presentToken ? presentToken : (consumer ? CONSUMER_TOKEN : PRODUCER_TOKEN);
  // if (ended) {
  //   return <EndScreen />
  // }
  return (<div style={{
    height: "100vh",
    width: "100vw",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center"
  }}>
    <div>
      <Stream
          //@ts-ignore
          noInteractionNeeded={noInteract}
          uaLayout={true}
          backendUrl={"wss://lb1.getstream.in"}
          token={token}
          chat={true}
          destroyEndpoint={destroyEndPoint}
          theme="light"
          accessKey={accessKey}
      />
    </div></div>)
}