
const componentStyles = {
  cardRoot: {
    boxShadow: "0 0 2rem 0 rgba(" + "gray" + ",.15)" + "!important",
    backgroundColor: "#f5f6fa",
    height: "100vh"
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  chip: {
    "& .MuiChip-label" : {
      paddingLeft: ({isMobileLandscape}) => {
        return isMobileLandscape ? 0 : 12
      }, paddingRight: ({isMobileLandscape}) => isMobileLandscape ? 0 : 12
    }
  },
  cardHeaderSecond: {
    display: "flex",
    alignItems: "center",
  },
  dNoneDSmBlock: {
    display: "none"
  },
  primaryColor: {
    color: "purple" + "!important",
  },
  anchor: {
    marginTop: ".25rem",
    display: "inline-flex",
    alignItems: "center",
    marginRight: ".75rem",
    color: "black",
    fontSize: ".875rem",
    textDecoration: "none",
  },
  justifyContentSmEnd: {
    
  },
  avatarRootXS: {
    width: "40px",
    height: "40px",
  },
  mediaRoot: {
    "&:hover $avatarRootMedia": {
      transform: "scale(1.1)",
    },
  },
  avatarRootMedia: {
    marginTop: "-1rem",
    marginRight: "-2rem",
    position: "relative",
    zIndex: 1,
    border: "4px solid " + "white",
    transition: "all .15s ease",
    width: "28px",
    height: "28px",
  },
  avatarRootMediaSmall: {
    marginTop: "-.2rem",
    marginRight: "-.3rem",
    position: "relative",
    zIndex: 1,
    border: "1px solid " + "white",
    transition: "all .15s ease",
    width: "20px",
    height: "20px",
  },
  peerJoinedCard: {
    backgroundColor: "white",
    minWidth: ({isMobileLandscape}) => isMobileLandscape ? "60px" : "100px",
    borderRadius: "7px",
    border: "1px solid rgb(240, 244, 247)",
    paddingTop: ({isMobileLandscape}) => isMobileLandscape ? 3: 5,
    paddingBottom: ({isMobileLandscape}) => isMobileLandscape ? 3 : 5,
    paddingLeft: 10,
    paddingRight: 10,
    display: "inline-flex",
    justifyContent: "center",
    marginTop: ({isMobileLandscape}) => isMobileLandscape ? 3: 7,
  },
  avatarRootMediaComment: {
    width: "45px",
    height: "45px",
    marginRight: "1.5rem",
  },
  mediaComment: {
    backgroundColor: "white",
    minWidth: "150px",
    borderRadius: "12px",
    border: "1px solid rgb(240, 244, 247)"
  },
  mediaCommentSmall: {
    backgroundColor: "white",
    minWidth: "80px",
    borderRadius: "12px",
    border: "1px solid rgb(240, 244, 247)"
  },
  boxImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  avatarRounded: {
    borderRadius: ".375rem!important",
  },
};

export default componentStyles;
