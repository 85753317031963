import { Grid } from '@material-ui/core'
import React from 'react'
import { IndividualVideo } from './IndividualVideo'

export const Layout2 = (props) => {
  const isMobile = props.isMobile

  if (props.layout == 1) {
    if (!isMobile) {
      <div>
        <Grid container spacing={2}>
          {props.videoStreams.map(vs =>
            <Grid key={vs.assignedId} item xs={6} md={6} lg={6}>
              <IndividualVideo {...props}  {...vs} />
            </Grid>
          )}
        </Grid>
      </div>
    }
    return (
      <div>
        <Grid container spacing={2}>
          {props.videoStreams.map(vs =>
            <Grid key={vs.assignedId} item xs={12} md={6} lg={6}>
              <IndividualVideo {...props}  {...vs} />
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
  return <div>
    <Grid container spacing={2}>
      {/*
      @ts-ignore*/}
    <Grid item sm={0} md={1} lg={1}>
    </Grid>
      <Grid item sm={12} md={10} lg={10}>
        <IndividualVideo {...props} key={props.videoStreams[0].assignedId} {...props.videoStreams[0]} />
        <div style={{display: "flex"}}>
          <div style={{
            position: "absolute", width: "50%", maxWidth: "350px", zIndex: 10000, marginTop: -235
          }}>
            <IndividualVideo {...props} key={props.videoStreams[1].assignedId} {...props.videoStreams[1]} />
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
}
