import { IconButton, makeStyles } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import React from 'react';
import { AROUND } from '../../constants';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import OutsideAlerter from '../OutsideAlerter';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//@ts-ignore
const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: 5,paddingRight: 5, margin: 0, fontSize: 10
  },
  listRoot: {
    padding: 0,margin: 0
  },
  btnSmall: {
    background: "black",
    width: 14,
    height: 14,
    padding: 10,
    "border-top-left-radius": 10,
    "border-bottom-left-radius": 10,
    "border-top-right-radius": 0,
    "border-bottom-right-radius": 0,
    //@ts-ignore
    padding: 14
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
  img: {
    width: 25
  },
  btn: {
    background: "#ecf0f1",
    width: 65,
    height: 50,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#dfe4ea"
    }
  },
  dropdown: {
    position: "relative",
    display: "inline-block",
    '&:hover': {
      background: "#ced6e0"
    }
  },
  dropdownContent:  {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: "1",
  },
}));

export const MuteVideoIcon = ({
  setVideoStreams,
  assignedId,
  videoMuted,
  localVideoStreams,
  cameras,
  setVideoMuted,
  view
}) => {
  const classes = useStyles({videoMuted});
  const [showDevices, setShowDevices] = React.useState(false);
  const toggleMute = (newAudioMuted) => {
    const videoAssignedId = localVideoStreams.find(x => x.type !== "desktop")?.assignedId;
    if (!videoAssignedId) {
      return;
    }

    if (newAudioMuted) {
      window.adapter.pauseMediaProducer({
        assignedId: videoAssignedId
      });
      window.adapter.videoMuteToggled(newAudioMuted);
      setVideoStreams(streams => streams.map(vs => {
        if (vs.peerId !== "local") {
          return vs;
        }
        return {
          ...vs,
          isMuted: true
        }
      }));
    } else {
      window.adapter.resumeMediaProducer({ assignedId: videoAssignedId });
      window.adapter.videoMuteToggled(newAudioMuted);
      setVideoStreams(streams => streams.map(vs => {
        if (vs.peerId !== "local") {
          return vs;
        }
        return {
          ...vs,
          isMuted: false
        }
      }));
    }
  }

  const toggleMuteParent = () => {
    toggleMute(!videoMuted);
    setVideoMuted(x => !x);
  }

  if (view === AROUND) {
    return (
      <>
        {/*
        //@ts-ignore */}
        <IconButton onClick={toggleMuteParent} className={classes.btnSmall} size="small">
          {/*
        //@ts-ignore */}
          {videoMuted ? <VideocamOffIcon className={classes.btnAroundInner} /> : <VideocamIcon className={classes.btnAroundInner} />}
        </IconButton>
      </>
    )
  }

  const handleCameraChange = (deviceId) => {
    let constraints = { deviceId: { exact: deviceId } };
    if (!deviceId || deviceId === "") {
      //@ts-ignore
      constraints = true;
    }
    navigator.mediaDevices.getUserMedia({
      video: {
        ...constraints,
        ...window.adapter.aspectRatio["1"],
        advanced: [
          { aspectRatio: { exact: 1.6667 } },
        ]
      }
    })
      .then(stream => {
        const track = stream.getVideoTracks()[0];
        {/*
        //@ts-ignore */}
        window.adapter.replaceTrack({ track, stream, assignedId, constraints });
        // cameraReplaced(track, stream);
      })
  }

  return (
    <span style={{position: "relative"}}>
      {/*
        //@ts-ignore */}
      <div className={classes.dropdownContent} style={{width: 220, visibility: showDevices ? "" : "hidden"}}>
      {/*
        //@ts-ignore */}
        <List component="nav" className={classes.listRoot} aria-label="contacts">
      {/*
        //@ts-ignore */}
          {cameras.map((x, index) => <div key={index} onClick={() => handleCameraChange(x.deviceId)}><ListItem className={classes.listItem} button>
            <ListItemText primary={x.label ? x.label.substr(0, 22) + (x.label.length > 21 ? "..." : "") : `Cam ${index + 1}`} />
          </ListItem></div>)}
        </List>
      </div>
      {/*
        //@ts-ignore */}
      <IconButton style={{border: "1px solid #ced6e0", background: videoMuted ? "#e94435" : "#f1f2f6", borderRadius: 7}} onClick={toggleMuteParent} className={classes.btn} size="small">
        <div>
        <div style={{marginBottom: -4}}>{videoMuted ? <VideocamOffIcon style={{color: "white"}} /> : <VideocamIcon />}</div>
          <div style={{fontSize: 11, marginBottom: -5, color: videoMuted ? "white" : "black" }}>
            {videoMuted ? "Start video" : "Stop video"}
          </div>
        </div>

        {/*
        //@ts-ignore */}
        <OutsideAlerter onClick={() => {
          setTimeout(() => setShowDevices(false), 150)
        }}>
          <KeyboardArrowUpIcon onClick={(e) => {
            setShowDevices(true)
            e.stopPropagation();
            {/*
        //@ts-ignore */}
          }} className={classes.dropdown} style={{color: videoMuted ? "white" : "", position: "absolute", "top": "0", "right": "0", borderRadius: 3, width: 20, height: 20 }} >
          </KeyboardArrowUpIcon>
        </OutsideAlerter>
      </IconButton>
    </span>
  )
}
