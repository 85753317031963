import React from 'react';
import Divider from "@material-ui/core/Divider";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Chip, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export const PeerList = function({peers, type, isMobileLandscape}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {peers?.sort((a, b) => a.handRaised ? -1 : 1)?.sort((a, b) => a.type === "producer" ? -1 : 1).map(peer =><> <div style={{"display": "flex", justifyContent: "space-between"}}>
        <div style={{"display": "flex"}}>
        {!isMobileLandscape && <Avatar
          alt="..."
          src={`https://ui-avatars.com/api/?name=${peer.name}&background=2c3e50&color=ecf0f1&rounded=true`}
        />}
        <div style={{marginTop: isMobileLandscape ? 5 : 10, marginLeft: 10}}>
        <Typography style={{fontSize: isMobileLandscape ? 13 : 18}}>
          {peer.name?.length > 10 ? peer.name.substr(0, 8) + "..." : peer.name}
        </Typography>
        </div>
        </div>
        <div>
        {peer.handRaised && <span style={{marginRight: 5, fontSize: 20}}>🖐</span>}
        {peer.type === "producer" && <span style={{marginRight: 5, fontSize: isMobileLandscape ? 15 : 20}}>👨‍🏫</span>}
        {peer.type !== "producer" && <span style={{marginRight: 5, fontSize: isMobileLandscape ? 15 : 20}}>🧑‍🎓‍</span>}
        
        {" "}
          {/*
          //@ts-ignore */}
        {type === "producer" && peer.type !== "producer" && <Chip onClick={() => {
            window.adapter.inviteUser(peer.peerId)
          }} label={`Invite`}>
        </Chip>}
          {/*
          //@ts-ignore */}
        {type === "producer" && peer.type === "producer" && <Chip 
        style={{height: isMobileLandscape ? "20px" : "", fontSize: isMobileLandscape ? "9px" : ""}}
        onClick={() => {
            window.adapter.removeUserAsProducer(peer.peerId)
            window.adapter.raiseHand(false, peer.peerId)
          }} label={"Suspend"}>
          </Chip>}
          </div>
      </div>
      
      <Box
        component={Divider}
        marginTop={isMobileLandscape ? "5px!important" : "1rem!important"}
        marginBottom={isMobileLandscape ? "5px!important" : "1rem!important"}
      />
      </>)}
    </div>
  );
}