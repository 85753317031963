import React from 'react';
import { AroundVideos } from '../videos/AroundVideos';
import { IndividualVideo } from './IndividualVideo';

export const AroundScreenshare = ({ videoStreams, screenShareOn, audioState, setVideoStreams }) => {
  const screensharedVideo = videoStreams.find(x => x.isDesktop);
  const nonScreensharedVideos = videoStreams.filter(x => !x.isDesktop);

  return (
    <div>
      {screensharedVideo && <IndividualVideo audioState={audioState} {...screensharedVideo} />}
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <AroundVideos
          height={100}
          // @ts-ignore
          setVideoStreams={setVideoStreams}
          videoStreams={nonScreensharedVideos}
        />
      </div>
    </div>
  )
}
