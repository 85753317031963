import { Button, Popover } from "@material-ui/core";
import React from "react";

export default function Pages({
  pages,
  currentPage,
  setCurrentPage,
  activePdf,
  fetchPdfs,
}) {
  const onPageChange = (i) => {
    setCurrentPage(i);
    window.adapter.changePage(i);
    if (pages.length - 1 == i && activePdf?.hasMore) {
      fetchPdfs(activePdf.file);
    }
  };
  return (
    <div style={{ marginBottom: "2rem" }}>
      {pages.map((page, i) => (
        <PageButton
          page={page}
          i={i}
          onPageChange={onPageChange}
          currentPage={currentPage}
        />
      ))}
    </div>
  );
}

function PageButton({ page, i, currentPage, onPageChange }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (page.background.startsWith("http")) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        size="small"
        id={`mouse-over-popover-${i}`}
        style={{ minWidth: "40px", margin: "5px" }}
        onClick={() => onPageChange(i)}
        color="primary"
        variant={currentPage == i ? "contained" : "outlined"}
        aria-owns={open ? `mouse-over-popover-${i}` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {i + 1}
      </Button>
      <Popover
        id={`mouse-over-popover-${i}`}
        style={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: 90,
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
      >
        <img src={page.background} width={120} />
      </Popover>
    </>
  );
}
