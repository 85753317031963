import { CircularProgress, Typography, Slide } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import config from "../config";
import { FULLSCREEN } from '../constants';
import { Interact } from './Interact';
import StreamInner from './Stream';
import { EndScreen } from './EndScreen';
const THEME_DEFAULTS = {
  primary: "#f3f3fc",
  secondary: "#5138ee",
  accent: "#f3f3fc",
  contrast: "#6d6af8",
  background: "#f3f3fc"
}
export const Stream = function ({
    room, destroyEndpoint, title = "Join Class", disabled = false, token, accessKey, urls, theme = {}, chat: chatOuter, view = "fullScreen", onRatingSet, testMode, data, minimal, onEnded, eventKind = false, uaLayout = false, noInteractionNeeded = false, hlsUrl
}) {
  theme = {
    ...THEME_DEFAULTS,
    ...theme
  }
  const [chat, setChat] = useState(chatOuter);
  const [interacted, setInteracted] = useState(false);
  const [restart, setRestart] = useState(false);
  const [producerState, setProducerState] = useState(false);
  const [initialVideoProps, setInitialVideoProps] = useState([]);
  const [constraintsSet, setConstraintsSet] = useState(false);
  const [forcedSfu, setForcedSfu] = useState(false);
  const [backendUrl, setBackendUrl] = useState((urls|| config.urls)[Math.floor((urls|| config.urls).length * Math.random())])
  const isFullScreen = view === FULLSCREEN;
  // @ts-ignore
  window.hlsUrl = hlsUrl;

  useEffect(() => {
    if (restart) {
      window.setTimeout(() => setRestart(false), 2000)
    }
    console.log("version V1");
  }, [restart])

  useEffect(() => {
    window.setTimeout(() => {
      if (noInteractionNeeded) {
        setInteracted(true)
      }
    }, 300)
  }, [noInteractionNeeded])

  const setLeft = () => {
    setInitialVideoProps([]);
    initVideoConstraints();
    setInteracted(false);
    if (typeof onEnded === "function")
      onEnded(true);
  }

  const initVideoConstraints = () => {
    let cameraSet = false;
    const self = this;
    if (new URLSearchParams(window.location.search).get("canvas")) {
      setConstraintsSet(true);
      return;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        devices.map(device => {
          // @ts-ignore
          if (device.kind.includes("video") && !cameraSet) {
            cameraSet = true;
            setConstraintsSet(true);
            setInitialVideoProps([{ constraints: { video: { deviceId: device.deviceId }, type: "webcam" } }])
          }
        })
        if (!cameraSet) {
          setConstraintsSet(true)
          setInitialVideoProps([{ constraints: { video: {} }, type: "webcam" }])
        }
      })
  }
  useEffect(() => {
    initVideoConstraints();
  }, [])

  const _getBackgroundColor = () => {
    // @ts-ignore
    return (view === FULLSCREEN && !minimal) ? theme.background : ""
  };

  // if (left) {
  //   return <div style={{ minHeight: isFullScreen ? "100vh" : "", "background": _getBackgroundColor() }}>
  //     <EndScreen
  //       ratingSet={(val) => {
  //         if (onRatingSet && typeof onRatingSet === "function")
  //           onRatingSet(val)
  //       }}
  //       isFullScreen={isFullScreen} />
  //   </div>
  // }

  const getRestartComp = () => {
    return <div style={{ "background": _getBackgroundColor(), color: "white", height: isFullScreen ? "100vh" : "", display: "flex", flexDirection: "column", alignItems: "center", "justifyContent": "center" }}>
    </div>
  }
  const getStreamComp = () => {
    return <div style={{ "background": _getBackgroundColor() }}>
      <StreamInner
        hlsUrl={hlsUrl}
        eventKind={eventKind}
        destroyEndpoint={destroyEndpoint}
        uaLayout={uaLayout}
        room={room}
        theme={theme}
        testModeData={data}
        testMode={testMode}
        setForcedSfu={setForcedSfu}
        setRestart={setRestart}
        setInitialVideoProps={setInitialVideoProps}
        initialVideoProps={initialVideoProps}
        producerState={producerState}
        debug={new URLSearchParams(window.location.search).get("debug")}
        setProducerState={setProducerState}
        isP2p={config.options.p2p && !forcedSfu}
        admin={config.options.admin}
        audiooff={config.options.audiooff}
        token={token}
        accessKey={accessKey}
        backendUrl={backendUrl}
        canvas={config.options.canvas}
        simulcast={config.options.simulcast}
        chat={chat}
        setChat={setChat}
        isFullScreen={isFullScreen}
        view={view}
        setLeft={setLeft}
      />
    </div>
  }
  // if (noInteractionNeeded && !interacted) {
  //   return <></>
  // }
  return (<>
    {!(new URLSearchParams(window.location.search).get("canvas")) && !interacted && <div style={{ minHeight: isFullScreen && !minimal ? "100vh" : "", "background": _getBackgroundColor() }}>
      <Interact
        disabled={disabled}
        interacted={interacted}
        theme={theme}
        minimal={minimal}
        onFinish={() => {
          setInteracted(true)
        }}
        title={title}
        isFullScreen={isFullScreen && !minimal}
        setLeft={setLeft}
      />
    </div>}
    {constraintsSet && <>
      {restart && getRestartComp()}
      {interacted && !restart && getStreamComp()}
    </>}
  </>)
}

