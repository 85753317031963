import React, { useState } from 'react'
import { useEffect } from 'react'
import config from '../config'
import axios from "axios";
import { Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  outer: {
    //@ts-ignore
    borderRadius: ({ isMobileLandscape }) => isMobileLandscape ? 3 : 10,
    background: "#203b68"
  },
  description: {
    display: "flex",
    justifyContent: "space-between",
    //@ts-ignore
    padding: ({ isMobileLandscape }) => isMobileLandscape ? 3 : 15,
    color: "white",
  },
  optionUndecided: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    //@ts-ignore
    borderRadius: ({ isMobileLandscape }) => isMobileLandscape ? 5 : 10,
    background: "#344b71",
    //@ts-ignore
    marginLeft: ({ isMobileLandscape }) => isMobileLandscape ? 2 : 20,
    //@ts-ignore
    marginRight: ({ isMobileLandscape }) => isMobileLandscape ? 2 : 20,
    //@ts-ignore
    marginBottom: ({ isMobileLandscape }) => isMobileLandscape ? 2 : 10,
    //@ts-ignore
    padding: ({ isMobileLandscape }) => isMobileLandscape ? 0 : 2,
    color: "white"
  }
}));
let pollInterval;

export const Poll = ({ pollData, accessKey, token, isMobileLandscape }) => {
  const classes = useStyles({ isMobileLandscape });
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [response, setResponse] = useState(null);
  const [userResponses, setUserResponses] = useState([0, 0, 0, 0]);

  const refreshRespnses = () => {
    axios.get(`${config.cloudFnUrl}/getPoll?poll_id=${pollData.poll_id}&accessKey=${accessKey}&token=${token}`)
      .then((response) => {
        setDescription(response.data.description)
        setLoading(false);
        setOptions(response.data.options || []);
        setResponse(r => r || response.data.response || null);
        setUserResponses(r => [
          Math.max(r[0], response.data.option1),
          Math.max(r[1], response.data.option2),
          Math.max(r[2], response.data.option3),
          Math.max(r[3], response.data.option4),
        ])
      })
  }

  useEffect(() => {
    setLoading(true);
    refreshRespnses();
    pollInterval = window.setInterval(() => {
      refreshRespnses();
    }, 3000);
    return () => {
      window.clearInterval(pollInterval);
    }
  }, [pollData.poll_id]);


  const reducer = (accumulator, curr) => accumulator + curr;
  const totalVotes = userResponses.reduce(reducer);
  if (loading) {
    return <></>
  }
  return (
    <div className={classes.outer}>
      <div className={classes.description}>
        <div>
          {/*
            //@ts-ignore */}
          <Typography variant={isMobileLandscape ? "caption" : "subtitle2"} component="subtitle1">
            {description}
          </Typography>
        </div>
        <div style={{ minWidth: 70, float: "right" }}>
          {/*
            //@ts-ignore */}
          {!isMobileLandscape && <Typography variant={isMobileLandscape ? "caption" : "subtitle2"} component="subtitle1">
            {totalVotes} vote(s)
          </Typography>}
          {isMobileLandscape && <div style={{ height: 5 }}></div>}
        </div>
      </div>
      {options.filter(x => (x && x !== "")).map(
        (option, index) => <div
          className={classes.optionUndecided}
          style={{
            width: (response && totalVotes !== 0) ? Math.max((userResponses[index] / totalVotes), 0.2) * 90 + "%" : "90%"
          }}
          // disabled={loading || (response && response !== index + 1)} 
          // variant="contained" 
          // color="primary"
          onClick={() => {
            if (response) {
              return;
            }
            setResponse(index + 1);
            setUserResponses(userResponses => {
              let x = [...userResponses];
              x[index]++;
              return x;
            })
            axios.post(`${config.cloudFnUrl}/submitPollResponse`, {
              "accessKey": accessKey,
              "token": token,
              "poll_id": pollData.poll_id,
              "response": index + 1,
            })
          }}>
          <div style={{ paddingLeft: 10 }}>
            {/*
            //@ts-ignore */}
            <Typography style={{ fontSize: isMobileLandscape ? 8 : "" }} variant={isMobileLandscape ? "caption" : "subtitle2"} component="subtitle1">
              {option || "ABCD".charAt(index)}
            </Typography>
          </div>
          <div>
            <Typography style={{ fontSize: isMobileLandscape ? 8 : "" }} variant={isMobileLandscape ? "caption" : "subtitle2"}>
              {totalVotes !== 0 && response ? ((userResponses[index] / totalVotes) * 100).toFixed() + "%" : ""}
            </Typography>
          </div>
        </div>
      )}
      <br />
    </div>
  )
}
