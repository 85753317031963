//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import { AroundVideos } from "./videos/AroundVideos";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton, Popover, Slider, SvgIcon } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  BorderColor,
  CropDin,
  Edit,
  FiberManualRecordOutlined,
  FormatColorFill,
  LineWeight,
  PanoramaFishEyeOutlined,
  RemoveOutlined,
  Undo,
} from "@material-ui/icons";

let current = {
  color: "black",
  stroke: 3,
  shape: "pen",
};
function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
      // @ts-ignore
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
let drawing = false;
let curStrokes = [];
let whiteBoardData = [];
let canvas;
let context;
let imageUrl = null;
let base_image = null;
let baseImageSet = false;
//let isEraser = false;
let backgroundColor = "white";
const colors = ["black", "#2ecc71", "#e74c3c", "#3498db"];
const bgColors = ["white", "yellow", "#2ecc71", "#e74c3c", "#3498db"];
let mainType;
export const Whiteboard = ({ setVideoStreams, videoStreams, type, image, uaLayout, pdf, currentPage=0, setCurrentPage, setWhiteBoardData }) => {
  const ref = useRef(null);
  const outer = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElColorPopover, setAnchorElColorPopover] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElBgColorPopover, setAnchorElBgColorPopover] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElShapesPopover, setAnchorElShapesPopover] = React.useState<HTMLButtonElement | null>(null);
  const [isEraser, setIsEraser] = useState(false);
  const shapes = [
    {
      name: "pen",
      icon: <Edit />,
    },
    {
      name: "line",
      icon: <RemoveOutlined />,
    },
    {
      name: "rectangle",
      icon: <CropDin />,
    },
    {
      name: "circle",
      icon: <PanoramaFishEyeOutlined />,
    },
  ];

  useEffect(() => {
    if (ref && ref.current) {
      canvas = ref.current;
      ref.current.onwheel = function (event) {
        if (iOS()) event.preventDefault();
      };

      ref.current.onmousewheel = function (event) {
        if (iOS()) event.preventDefault();
      };
      context = canvas.getContext("2d");
      _initCanvas();
      onResize();
    }
  }, [ref]);

  // useEffect(() => {
  //   if(whiteBoardData.length > 0) {
  //     curStrokes = whiteBoardData[currentPage].strokes || [];
  //     renderShapes(curStrokes);
  //   }
  // }, [currentPage]);

  // useEffect(() => {
  //   setWhiteBoardData(whiteBoardData)
  // }, [whiteBoardData])

  useEffect(() => {
    imageUrl = image;
    baseImageSet = false;
    drawImage();
  }, [image]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawImageOnCanvas = () => {
    const offset = canvas.height * 0.1;
    let width = canvas.width;
    let height = canvas.height - offset;
    let left = 0,
      top = offset;
    if (width / height > base_image.naturalWidth / base_image.naturalHeight) {
      width = (height * base_image.naturalWidth) / base_image.naturalHeight;
      left = (canvas.width - width) / 2;
    } else {
      height = (width * base_image.naturalHeight) / base_image.naturalWidth;
      top = (canvas.height - height) / 2;
    }
    context.drawImage(base_image, left, top, width, height);
  };

  const drawImage = () => {
    if (!imageUrl) {
      return;
    }
    if (!base_image || base_image.src != imageUrl) {
      base_image = new Image();
      base_image.src = imageUrl;
      base_image.onload = function (e) {
        baseImageSet = true;
        drawImageOnCanvas();
      };
    } else {
      drawImageOnCanvas();
    }
  };

  useEffect(() => {
    let throttledMove = throttle(onMouseMove, 30);
    if (type === "producer") {
      canvas.addEventListener("mousedown", onMouseDown, false);
      canvas.addEventListener("mouseup", onMouseUp, false);
      canvas.addEventListener("mouseout", onMouseUp, false);
      canvas.addEventListener("mousemove", throttledMove, false);
      canvas.addEventListener("click", eraseShapes, false);

      //Touch support for mobile devices
      canvas.addEventListener("touchstart", onMouseDown, false);
      canvas.addEventListener("touchend", onMouseUp, false);
      canvas.addEventListener("touchcancel", onMouseUp, false);
      canvas.addEventListener("touchmove", throttledMove, false);
    } else {
      canvas.removeEventListener("mousedown", onMouseDown, false);
      canvas.removeEventListener("mouseup", onMouseUp, false);
      canvas.removeEventListener("mouseout", onMouseUp, false);
      canvas.removeEventListener("mousemove", throttledMove, false);

      //Touch support for mobile devices
      canvas.removeEventListener("touchstart", onMouseDown, false);
      canvas.removeEventListener("touchend", onMouseUp, false);
      canvas.removeEventListener("touchcancel", onMouseUp, false);
      canvas.removeEventListener("touchmove", throttledMove, false);
    }
    return () => {
      canvas.removeEventListener("mousedown", onMouseDown, false);
      canvas.removeEventListener("mouseup", onMouseUp, false);
      canvas.removeEventListener("mouseout", onMouseUp, false);
      canvas.removeEventListener("mousemove", throttledMove, false);
      canvas.removeEventListener("click", eraseShapes, false);

      //Touch support for mobile devices
      canvas.removeEventListener("touchstart", onMouseDown, false);
      canvas.removeEventListener("touchend", onMouseUp, false);
      canvas.removeEventListener("touchcancel", onMouseUp, false);
      canvas.removeEventListener("touchmove", throttledMove, 30, false);
    }
  }, [type, isEraser]);
  useEffect(() => {
    if (outer && outer.current) {
      onResize();
    }
  }, [outer]);

  function eraseShapes(e) {
    if (!isEraser) return;
    const x = event.offsetX;
    const y = event.offsetY;

    for (let i = curStrokes.length - 1; i >= 0; i--) {
      const shape = curStrokes[i];

      // check if the clicked point is inside the shape
      if (shape.type === "circle") {
        const radius = Math.sqrt((shape.x1 - shape.x0) ** 2 + (shape.y1 - shape.y0) ** 2);
        if (Math.sqrt((x - shape.x0) ** 2 + (y - shape.y0) ** 2) <= radius) {
          window.adapter.sendViaBotDataChannel(
            JSON.stringify({
              type: "removeStrokes",
              removedIds: [shape.id] ,
            })
          );
          break;
        }
      } else if (shape.type === "rectangle") {
        const x0 = Math.min(shape.x0, shape.x1);
        const x1 = Math.max(shape.x0, shape.x1);
        const y0 = Math.min(shape.y0, shape.y1);
        const y1 = Math.max(shape.y0, shape.y1);
        if (x >= x0 && x <= x1 && y >= y0 && y <= y1) {
          window.adapter.sendViaBotDataChannel(
            JSON.stringify({
              type: "removeStrokes",
              removedIds: [shape.id] ,
            })
          );
          break;
        }
      } else if (shape.type === "line") {
        // check if the clicked point is near the line
        const distance =
          Math.abs((y - shape.y0) * (shape.x1 - shape.x0) - (x - shape.x0) * (shape.y1 - shape.y0)) /
          Math.sqrt((shape.x1 - shape.x0) ** 2 + (shape.y1 - shape.y0) ** 2);
        if (distance <= 5) {
          window.adapter.sendViaBotDataChannel(
            JSON.stringify({
              type: "removeStrokes",
              removedIds: [shape.id] ,
            })
          );
          break;
        }
      } else if (shape.type === "pen") {
        const distance =
          Math.abs((y - shape.y0) * (shape.x1 - shape.x0) - (x - shape.x0) * (shape.y1 - shape.y0)) /
          Math.sqrt((shape.x1 - shape.x0) ** 2 + (shape.y1 - shape.y0) ** 2);
        if (distance <= 5) {
          window.adapter.sendViaBotDataChannel(
            JSON.stringify({
              type: "removeStrokes",
              removedIds: [shape.id] ,
            })
          );
          break;
        }
      }
    }
  }

  function onMouseDown(e) {
    drawing = true;
    // @ts-ignore
    current.x = (e.clientX || e.touches[0].clientX) - canvas.getBoundingClientRect().left;
    // @ts-ignore
    current.y = (e.clientY || e.touches[0].clientY) - canvas.getBoundingClientRect().top;
    current.id = Math.random();
    e.preventDefault();
    if (current.shape === "pen") {
      current.start = true;
    }
    return false;
  }

  function onMouseUp(e) {
    if (isEraser) {
      drawing = false;
      return;
    }
    if (!drawing) {
      return;
    }
    drawing = false;
    // @ts-ignore
    const endX = (e.clientX || e.touches[0].clientX) - canvas.getBoundingClientRect().left;
    const endY = (e.clientY || e.touches[0].clientY) - canvas.getBoundingClientRect().top;
    // @ts-ignore
    var w = canvas.width;
    var h = canvas.height;
    const data = { type: current.shape, x0: current.x / w, y0: current.y / h, x1: endX / w, y1: endY / h, ...current };
    if (current.shape === "pen") {
      data.end = true;
    }
    window.adapter.sendViaBotDataChannel(
      JSON.stringify({
        type: "wb",
        data: [data],
      })
    );
    e.preventDefault();
    return false;
  }

  function undo() {
    window.adapter.sendViaBotDataChannel(
      JSON.stringify({
        type: "removeStrokes",
        removedIds: [curStrokes[curStrokes.length - 1]?.id],
      })
    );
  }

  function selectShape(shape) {
    //isEraser = false;
    setIsEraser(false);
    current.shape = shape;
    current.color = current.color == "white" ? "black" : current.color;
    current.stroke = current.stroke;
    setAnchorElShapesPopover(null);
  }

  function onMouseMove(e) {
    if (!drawing) {
      return;
    }
    if (isEraser) {
      eraseShapes(e);
      return;
    }
    const endX = (e.clientX || e.touches[0].clientX) - canvas.getBoundingClientRect().left;
    const endY = (e.clientY || e.touches[0].clientY) - canvas.getBoundingClientRect().top;
    // @ts-ignore
   
    var w = canvas.width;
    var h = canvas.height;
    const data = { type: current.shape, x0: current.x / w, y0: current.y / h, x1: endX / w, y1: endY / h, ...current };

    if (current.start) {
      data.start = true;
      delete current["start"];
    }
    window.adapter.sendViaBotDataChannel(
      JSON.stringify({
        type: current.shape == "pen" ? "wb" : "replaceShape",
        data: [data],
      })
    );
    if (current.shape == "pen") {
      current.x = (e.clientX || e.touches[0].clientX) - canvas.getBoundingClientRect().left;
      current.y = (e.clientY || e.touches[0].clientY) - canvas.getBoundingClientRect().top;
    }
    e.preventDefault();
    return false;
  }

  function onColorUpdate(e) {
    current.color = e.target.className.split(" ")[1];
  }

  // limit the number of events per second
  function throttle(callback, delay) {
    var previousCall = new Date().getTime();
    return function () {
      var time = new Date().getTime();

      if (time - previousCall >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    };
  }

  function onResize() {
    if (!canvas || !outer.current) {
      return;
    }
    let width = outer.current.getBoundingClientRect().width;
    let height = outer.current.getBoundingClientRect().width / 1.667;
    let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    windowHeight -= 50;
    if (height > windowHeight) {
      height = windowHeight;
      width = height * 1.667;
    }
    const w = canvas.width;
    const h = canvas.height;
    curStrokes = curStrokes
      .map((r) => ({ ...r, x0: r.x0 / w, y0: r.y0 / h, x1: r.x1 / w, y1: r.y1 / h }))
      .map((r) => ({ ...r, x0: r.x0 * width, y0: r.y0 * height, x1: r.x1 * width, y1: r.y1 * height }));
    canvas.width = width;
    canvas.height = height;
    renderShapes(curStrokes);
  }

  function renderShapes(shapes) {
    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    if (imageUrl) {
      drawImage();
    } 

    // Render each shape in the shapes array
    shapes.forEach((shape) => {
      if (shape.type === "pen") {
        context.beginPath();
        context.strokeStyle = shape.color;
        context.lineWidth = shape.stroke;
        context.lineCap = "round";
        context.moveTo(shape.x0, shape.y0);
        context.lineTo(shape.x1, shape.y1);
        context.stroke();
        context.closePath();
      } else if (shape.type === "line") {
        context.beginPath();
        context.strokeStyle = shape.color;
        context.lineWidth = shape.stroke;
        context.moveTo(shape.x0, shape.y0);
        context.lineTo(shape.x1, shape.y1);
        context.stroke();
      } else if (shape.type === "rectangle") {
        context.beginPath();
        const width = shape.x1 - shape.x0;
        const height = shape.y1 - shape.y0;
        context.rect(shape.x0, shape.y0, width, height);
        context.strokeStyle = shape.color;
        context.lineWidth = shape.stroke;
        context.stroke();
      } else if (shape.type === "circle") {
        context.beginPath();
        const radius = Math.sqrt(Math.pow(shape.x1 - shape.x0, 2) + Math.pow(shape.y1 - shape.y0, 2));
        context.arc(shape.x0, shape.y0, radius, 0, 2 * Math.PI);
        context.strokeStyle = shape.color;
        context.lineWidth = shape.stroke;
        context.stroke();
      }
    });
  }

  const _initCanvas = () => {
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);
    drawImage();
    window.setTimeout(() => {
      window.adapter.registerWhiteboardCallback((data) => {
        const w = canvas.width;
          const h = canvas.height;
        if(data.type == "initialize") {
          whiteBoardData = data.data;
          setWhiteBoardData(whiteBoardData);
          return;
        }
        if(data.type == "pageChanged") {
          currentPage = data.currentPage;
          setCurrentPage(currentPage);
          curStrokes = whiteBoardData[currentPage].strokes.map((data) => ({ ...data, x0: data.x0 * w, y0: data.y0 * h, x1: data.x1 * w, y1: data.y1 * h }));;
          imageUrl = null;
          backgroundColor = "white";
          if(whiteBoardData[currentPage].background.startsWith("http")) {
            imageUrl = whiteBoardData[currentPage].background;
          } else {
            backgroundColor = whiteBoardData[currentPage].background;
          }
          renderShapes(curStrokes);
          return;
        }
        if(data.type == "addPage") {
          whiteBoardData = [...whiteBoardData, data.page];
          currentPage = whiteBoardData.length -1;
          setCurrentPage(currentPage);
          setWhiteBoardData(whiteBoardData);
          curStrokes = whiteBoardData[currentPage].strokes.map((data) => ({ ...data, x0: data.x0 * w, y0: data.y0 * h, x1: data.x1 * w, y1: data.y1 * h }));;
          imageUrl = null;
          backgroundColor = "white";
          if(data.page.background.startsWith("http")) {
            imageUrl = data.page.background;
          } else {
            backgroundColor = data.page.background;
          }
          renderShapes(curStrokes);
          return;
        }
        if(data.type == "changeBackground") {
          backgroundColor = data.background;
          whiteBoardData[currentPage].background = data.background;
          renderShapes(curStrokes);
          return;
        }
        if(data.type == "removeStrokes") {
          whiteBoardData[currentPage].strokes = whiteBoardData[currentPage].strokes.filter((stroke) => !data.removedIds.includes(stroke.id));
          curStrokes = curStrokes.filter((stroke) => !data.removedIds.includes(stroke.id));
          renderShapes(curStrokes);
          return;
        }
        if(data.type == "replaceShape") {
          whiteBoardData[currentPage].strokes = whiteBoardData[currentPage].strokes.filter((stroke) => data.data[0].id != stroke.id);
          whiteBoardData[currentPage].strokes = whiteBoardData[currentPage].strokes.concat(data.data);
          curStrokes = whiteBoardData[currentPage].strokes.map((data) => ({ ...data, x0: data.x0 * w, y0: data.y0 * h, x1: data.x1 * w, y1: data.y1 * h }));
          renderShapes(curStrokes);
          return;
        }
        if(data.type == "wb") {
          if (curStrokes.length > 0 && curStrokes[curStrokes.length - 1].id == data.data[0].id && data.data[0].shape != "pen") {
            //whiteBoardData[currentPage].strokes[curStrokes.length - 1] = data.data[0];
              } else {
              }
              whiteBoardData[currentPage].strokes = whiteBoardData[currentPage].strokes.concat(data.data);
          curStrokes = whiteBoardData[currentPage].strokes.map((data) => ({ ...data, x0: data.x0 * w, y0: data.y0 * h, x1: data.x1 * w, y1: data.y1 * h }));
          renderShapes(curStrokes);
          return;
        }
        if(data.type == "wbClear") {
          whiteBoardData[currentPage].strokes = [];
          curStrokes = [];
          renderShapes(curStrokes);
        }

        // if (datas.clear) {
        //   context.clearRect(0, 0, canvas.width, canvas.height);
        //   context.fillStyle = backgroundColor;
        //   context.fillRect(0, 0, canvas.width, canvas.height);
        //   drawImage();
        //   curStrokes = [];
        //   return;
        // }
        // if (datas.removedIds) {
        //   curStrokes = curStrokes.filter((stroke) => !datas.removedIds.includes(stroke.id));
        //   renderShapes(curStrokes);
        //   return;
        // }
        // if(datas[0].strokes) {
        //   whiteBoardData = datas;
        //   datas = datas[currentPage]?.strokes;
        // }
        // const w = canvas.width;
        // const h = canvas.height;
        // datas = datas.map((data) => ({ ...data, x0: data.x0 * w, y0: data.y0 * h, x1: data.x1 * w, y1: data.y1 * h }));
        // curStrokes = whiteBoardData[currentPage]?.strokes || [];
        // if (curStrokes.length == 0) {
        //   curStrokes = datas.filter((data, i) => data.shape == "pen" || datas.findLastIndex((stroke) => stroke.id == data.id) == i);
        // } else {
        //   if (datas?.length == 1 && curStrokes[curStrokes.length - 1].id == datas[0].id && datas[0].shape != "pen") {
        //     curStrokes[curStrokes.length - 1] = datas[0];
        //   } else {
        //     curStrokes = curStrokes.concat(datas);
        //   }
        //   //setWhiteBoardData([...whiteBoardData]);
        // }
        // whiteBoardData[currentPage].strokes = [...curStrokes];
        renderShapes(curStrokes);
      });
      window.adapter.initWhiteboard();
    }, 100);
    window.addEventListener("resize", onResize, false);
    return () => {
      window.removeEventListener("resize", onResize, false);
    };
  };

  return (
    <div ref={outer} style={{ position: "relative", display: "flex", justifyContent: "center" }}>
      <div>
        <canvas style={isEraser ? {cursor: "url('https://cdn-icons-png.flaticon.com/16/67/67732.png'),auto" } : {} } ref={ref}></canvas>
        {!uaLayout && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <AroundVideos
              height={100}
              // @ts-ignore
              setVideoStreams={setVideoStreams}
              videoStreams={videoStreams}
            />
          </div>
        )}
      </div>
      {type === "producer" && (
        <div style={{ position: "absolute", top: 10 }}>
          <IconButton
            onClick={() => {
              window.adapter.clearWhiteboard();
            }}
          >
            <DeleteIcon style={{ color: "black" }} />
          </IconButton>
          {!pdf && (
            <IconButton
              color={isEraser ? "primary" : "default"}
              onClick={() => {
                //isEraser = !isEraser;
                setIsEraser(!isEraser);
              }}
            >
              <SvgIcon style={{ color: isEraser ? "inherit" : "black" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>eraser</title>
                  <path d="M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z" />
                </svg>
              </SvgIcon>
            </IconButton>
          )}
          <IconButton color={!isEraser && current.shape == "pen" ? "primary" : "default"} onClick={(e) => selectShape("pen")}>
            <Edit style={{ color: !isEraser && current.shape == "pen" ? "inherit" : "black" }} />
          </IconButton>
          <IconButton id="colorPicker" onClick={(e) => setAnchorElColorPopover(e.currentTarget)}>
            <BorderColor style={{ color: current.color == backgroundColor ? "black" : current.color }} />
          </IconButton>
          <Popover
            id="colorPicker"
            open={Boolean(anchorElColorPopover)}
            anchorEl={anchorElColorPopover}
            onClose={() => setAnchorElColorPopover(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
              {colors.map((color, index) => (
                <IconButton
                  key={index}
                  onClick={() => {
                    current.color = color;
                    //current.stroke = current.stroke > 10 ? 2 : current.stroke;
                    setAnchorElColorPopover(null);
                  }}
                  style={{ width: 35, height: 35 }}
                >
                  <FiberManualRecordIcon style={{ color: color }} />
                </IconButton>
              ))}
            </div>
          </Popover>
          <IconButton id="bgColorPicker" onClick={(e) => setAnchorElBgColorPopover(e.currentTarget)}>
            <FormatColorFill style={{ color: "black" }} />
          </IconButton>
          <Popover
            id="bgColorPicker"
            open={Boolean(anchorElBgColorPopover)}
            anchorEl={anchorElBgColorPopover}
            onClose={() => setAnchorElBgColorPopover(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
              {bgColors.map((color, index) => (
                <IconButton
                  key={index}
                  onClick={() => {
                    window.adapter.changeBackground(color);
                    // backgroundColor = color;
                    // renderShapes(curStrokes);
                    setAnchorElBgColorPopover(null);
                  }}
                  style={{ width: 35, height: 35 }}
                >
                  {color == "white" ? <FiberManualRecordOutlined style={{ color: "black" }} /> : <FiberManualRecordIcon style={{ color: color }} />}
                </IconButton>
              ))}
            </div>
          </Popover>
          <IconButton id="shapePicker" onClick={(e) => setAnchorElShapesPopover(e.currentTarget)}>
            <SvgIcon style={{ color: "black" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>shape-outline</title>
                <path d="M11,13.5V21.5H3V13.5H11M9,15.5H5V19.5H9V15.5M12,2L17.5,11H6.5L12,2M12,5.86L10.08,9H13.92L12,5.86M17.5,13C20,13 22,15 22,17.5C22,20 20,22 17.5,22C15,22 13,20 13,17.5C13,15 15,13 17.5,13M17.5,15A2.5,2.5 0 0,0 15,17.5A2.5,2.5 0 0,0 17.5,20A2.5,2.5 0 0,0 20,17.5A2.5,2.5 0 0,0 17.5,15Z" />
              </svg>
            </SvgIcon>
          </IconButton>
          <Popover
            id="shapePicker"
            open={Boolean(anchorElShapesPopover)}
            anchorEl={anchorElShapesPopover}
            onClose={() => setAnchorElShapesPopover(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
              {shapes
                .filter((s) => s.name != "pen")
                .map((shape, index) => (
                  <IconButton key={index} onClick={() => selectShape(shape.name)} style={{ width: 30, height: 30, color: "black" }}>
                    {shape.icon}
                  </IconButton>
                ))}
            </div>
          </Popover>

          <IconButton id="strokeWidth" onClick={handleClick}>
            <LineWeight style={{ color: "black" }} />
          </IconButton>
          <Popover
            id="strokeWidth"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            style={{ height: "200px" }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Slider
              orientation="vertical"
              defaultValue={current.stroke}
              min={1}
              max={current.color == "white" ? 50 : 10}
              style={{ height: 100, margin: "1.2rem 1rem", color: "black" }}
              aria-label="Stroke Width"
              valueLabelDisplay="auto"
              onChange={(e, newValue) => {
                current.stroke = newValue as number;
              }}
            />
          </Popover>
          <IconButton onClick={undo}>
            <Undo style={{ color: "black" }} />
          </IconButton>
        </div>
      )}
    </div>
  );
};
