import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AROUND } from "../constants";

const useStyles = makeStyles((theme) => ({
  aroundSelect: {
    outline: "none",
    maxWidth: 19,
    padding: 5,
    "font-weight": "bold",
    "letter-spacing": ".15em",
    "border-radius": 0,
    borderColor: "black",
    background: "black",
    color: "white",
    "border-top-right-radius": 10,
    "border-bottom-right-radius": 10,
    "&:focus &:active": {
      outline: 0,
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    background: theme.palette.info.dark,
    color: theme.palette.primary.dark
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnSmall: {
    width: 6,
    height: 14,
    padding: 10,
    borderRadius: 6,
    // @ts-ignore
    padding: 15
  },
  btnAroundInner: {
    color: "white",
    width: 20
  },
}));

export const CameraSelector = ({ assignedId, cameras, cameraReplaced, view }) => {
  const classes = useStyles();

  const handleCameraChange = (e) => {
    const deviceId = e.target.value;
    let constraints = { deviceId: { exact: deviceId } };
    if (!deviceId || deviceId === "") {
      // @ts-ignore
      constraints = true;
    }
    navigator.mediaDevices.getUserMedia({
      video: {
        ...constraints,
        ...window.adapter.aspectRatio["1"],
        advanced: [
          { aspectRatio: { exact: 1.6667 } },
        ]
      }
    })
      .then(stream => {
        const track = stream.getVideoTracks()[0];
        // @ts-ignore
        window.adapter.replaceTrack({ track, stream, assignedId, constraints });
        // cameraReplaced(track, stream);
      })
  }

  if (view === AROUND) {
    return (
      <>
        {/* <ArrowDropDownIcon className={classes.btnAroundInner} >
        </ArrowDropDownIcon> */}
        <select className={classes.aroundSelect} onChange={handleCameraChange}>
          {cameras.map((camera, index) => <option key={camera.deviceId} value={camera.deviceId}>{camera.label || "Camera " + (index + 1)}</option>)}
        </select>
      </>
    )
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Camera</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleCameraChange}
      >
        {cameras.map((camera, index) => <MenuItem key={camera.deviceId} value={camera.deviceId}>{camera.label || "Camera " + (index + 1)}</MenuItem>)}
      </Select>
    </FormControl>
  )
}
