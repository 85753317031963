import { Button } from "./Button";
import React from 'react'

export const Interact = ({ title, theme, onFinish, isFullScreen, minimal, setLeft, interacted, disabled }) => {

  const getButtonNonInteracted = () => {
    return <Button size={"large"} disableHover={false} disabled={disabled} title={title} onClick={onFinish} theme={theme} />
  }

  const getButton = () => {
    if (!interacted) {
      return getButtonNonInteracted();
    }
    return null;
  }

  if (!isFullScreen || minimal) {
    return getButton()
  }

  return (
    //@ts-ignore
    <center>
      <div style={{
        height: "100vh",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center"
      }}>
        <div className="vertical-center">
          {getButton()}
        </div>
      </div>
      {/*
    // @ts-ignore */}
    </center>
  )
}
