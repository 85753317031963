import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AROUND } from '../constants';

const useStyles = makeStyles((theme) => ({
  aroundSelect: {
    outline: "none",
    maxWidth: 19,
    padding: 5,
    "font-weight": "bold",
    "letter-spacing": ".14em",
    "border-radius": 0,
    borderColor: "black",
    background: "black",
    color: "white",
    "border-top-right-radius": 10,
    "border-bottom-right-radius": 10,
    "&:focus &:active": {
      outline: 0,
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    background: theme.palette.info.dark,
    color: theme.palette.primary.dark
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const MicSelector = ({ assignedId, mics, cameraReplaced, view }) => {
  const classes = useStyles();

  const handleMicChange = (e) => {
    const deviceId = e.target.value;
    let constraints = { deviceId: { exact: deviceId } };
    if (!deviceId || deviceId === "") {
      // @ts-ignore
      constraints = true;
    }
    navigator.mediaDevices.getUserMedia({ audio: constraints })
      .then(stream => {
        const track = stream.getAudioTracks()[0];
        //@ts-ignore
        window.adapter.replaceTrack({ track, assignedId, isAudio: true });
      })
  }

  if (view === AROUND) {
    return (
      <>
        <select className={classes.aroundSelect} onChange={handleMicChange}>
          {mics.map((mic, index) => <option key={mic.deviceId} value={mic.deviceId}>{mic.label || "Mic " + (index + 1)}</option>)}
        </select>
      </>
    )
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Mic</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleMicChange}
      >
        {mics.map(mic => <MenuItem key={mic.deviceId} value={mic.deviceId}>{mic.label}</MenuItem>)}
      </Select>
    </FormControl>
  )
}
