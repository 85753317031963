import { Grid } from '@material-ui/core'
import React from 'react'
import { IndividualVideo } from './IndividualVideo'

export const Layout3 = (props) => {
  if(props.layout === 0) {
    return (
      <div style={{"paddingLeft": "1vw", "paddingRight": "1vw"}}>
        <Grid spacing={2} container justifyContent={"center"} alignItems={"center"}>
            <Grid key={props.videoStreams[0].assignedId} item sm={12} md={6} lg={6}>
              <IndividualVideo {...props} {...props.videoStreams[0]} />
            </Grid>
            <Grid key={props.videoStreams[1].assignedId} item sm={12} md={6} lg={6}>
              <IndividualVideo {...props} {...props.videoStreams[1]} />
            </Grid>
            <Grid sm={12} md={3} lg={3}>

            </Grid>
            <Grid key={props.videoStreams[2].assignedId} item sm={12} md={6} lg={6}>
              <IndividualVideo {...props} {...props.videoStreams[2]} />
            </Grid>
        </Grid>
      </div>
    )  
  }
  return (
    <div>
      <Grid container spacing={2}>
        {props.videoStreams.map(vs =>
          <Grid key={vs.assignedId} item sm={12} md={4} lg={4}>
            <IndividualVideo {...props} {...vs} />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
