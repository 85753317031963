import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

const useStyles = makeStyles((theme) => ({
  subtext: {
    marginTop: -5
  },
  icon: {
    marginTop: 22, 
    marginLeft: 10
  },
  flex: {
    display: "flex"
  },
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 3),
  },
}));

export default function PermissionModal({audioPermissionError, setAudioPermissionError, videoPermissionError, setVideoPermissionError}) {
  const classes = useStyles();
  const getTitle = () => {
    if (audioPermissionError && videoPermissionError) {
      return "No mic and camera access"
    }
    if (audioPermissionError) {
      return "No Mic access"
    }
    if (videoPermissionError) {
      return "No Camera access"
    }
  }
  const getIcon = () => {
    if (audioPermissionError && videoPermissionError) {
      return <MicOffIcon />
    }
    if (audioPermissionError) {
      return <MicOffIcon />
    }
    if (videoPermissionError) {
      return <VideocamOffIcon />
    }
  }
  const getSubTitle = () => {
    if (audioPermissionError && videoPermissionError) {
      return <>There seems to be an issue with your camera and mic permissions.
      <br/> Please provide access to the website to continue.</>
    }
    if (audioPermissionError) {
      return <>There seems to be an issue with your mic permissions.
      <br/> Please provide mic access to the website to continue.</>
    }
    if (videoPermissionError) {
      return <>There seems to be an issue with your camera permissions.
      <br/> Please provide cam access to the website to continue.</>
    }
  }
  return (
      <Modal
        open={audioPermissionError || videoPermissionError}
        onClose={() => {
          setVideoPermissionError(false);
          setAudioPermissionError(false)
        }}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
      >
        <div className={classes.paper}>
          <div className={classes.flex}>
            <div><h2 id="server-modal-title">{getTitle()}</h2></div>
            <div className={classes.icon}>
              {getIcon()}
            </div>
          </div>
          <p className={classes.subtext}>{getSubTitle()}</p>
        </div>
      </Modal>
  );
}

