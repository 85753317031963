export default {
    urls: ["wss://lb1.getstream.in", "wss://lb2.getstream.in"],
    pdfImageRoute: "https://d19wavw83h10r4.cloudfront.net",
    cloudFnUrl: "https://us-central1-stream-eb4c0.cloudfunctions.net",
    localWsServerUrl: "ws://localhost:3040",
    turnServerUrl: "turnind.acadcare.com:3478",
    pdfUploadRoute: "https://bulkuploaderapi.classx.co.in/api/v1/pdf-to-image",
    options: {
        p2p: new URLSearchParams(window.location.search).get("p2p"),
        admin: new URLSearchParams(window.location.search).get("admin") || false,
        audiooff: new URLSearchParams(window.location.search).get("audiooff") || false,
        token: new URLSearchParams(window.location.search).get("token") || null,
        accessKey: new URLSearchParams(window.location.search).get("accessKey") || null,
        canvas: new URLSearchParams(window.location.search).get("canvas") || false,
        session_creator: new URLSearchParams(window.location.search).get("session_creator") || false,
        dontuseturn: new URLSearchParams(window.location.search).get("dontuseturn"),
        adaptive: new URLSearchParams(window.location.search).get("adaptive"),
        simulcast: new URLSearchParams(window.location.search).get("simulcastoff") ? false : true,
        maxCameras: 2,
        p2pThreshold: 4
    },
    peerToBitrateMapping: {
        "1": 3,
        "2": 2.3,
        "3": 1.7,
        "4": 1.5,
        "5": 1.2,
        "6": 1,
        "7": 0.7,
        "8": 0.6,
        "9": 0.5,
        "10": 0.5
    }
}